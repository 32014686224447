import React,{useState} from "react";
import './CreateAccount.css';
import '../../../Common.css';
import { SendOTP,ResetPassword } from "../../../services/Api";

// components
import PrimarySolidButton from "../../Buttons/PrimarySolidButton/PrimarySolidButton";
import PopUpMainHeading from "../../Heading/PopUpMainHeading/PopUpMainHeading";
import AccountWarning from "../TextItems/AccountWarning/AccountWarning";
import PopUpSubHeading from "../../Heading/PopUpSubHeading/PopUpSubHeading";
import InputTextField from "../../InputFields/InputTextField/InputTextField";
import ScuccessPopUp from "../AccountCreationSuccess/ScuccessPopUp";
import PasswordInput from "../../InputFields/InputPasswordField/InputPasswordField";
import ErrorMessage from "../../ErrorMessage/ErrorMessage";




const ForgotPassword = ({ onClose, onInputChange, onSubmit,onComplete }) => {
    const [step, setStep] = useState(1); // Step to track the current stage (1: Mobile, 2: OTP, 3: Passwords)
    const [otpError, setOtpError] = useState(""); // To show OTP errors if any
    const [mobileError,setMobileError] = useState("");
    const [formData, setFormData] = useState({
        mobile: "",
        otp: "",
        newPassword: "",
        confirmPassword: "",
    });

    const handleOverlayClick = (e) => {
        if (e.target.className === "login-popup-overlay") {
            onClose();
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
      onInputChange(name, value); // Pass input data to parent component if needed
    };

    const handleSendOtp = async () => {
        if (!formData.mobile.trim()) {
            setMobileError("Please enter a valid mobile number");
            // alert("Please enter a valid mobile number");
        return;
        }
        const body={
            "mobile":formData.mobile.trim()
        }
        try {
            const response = await SendOTP({ body });
            if (response.error){
                setMobileError("error sending otp");
                // console.log("error sending otp")
                return;
            }
            else{
                if (response.data.resp_code === 1){
                    setStep(2);
                    setMobileError("");
                }
                else{
                    setMobileError("Error sending OTP. Please try again.");
                }
            }
            
        } catch (error) {
            setMobileError("Something went wrong. Please try again.");
        }
    };

    const handleResetAccount = async () => {
        const { newPassword, confirmPassword } = formData;
        if (!formData.otp.trim()) {
                setOtpError("Please enter the OTP");
            return;
        }
        if(!newPassword.trim().length>0 ){
            setOtpError("Please enter the new password.")
            return;
        }
        if(!confirmPassword.trim().length>0){
            setOtpError("Please confirm your new password.")
            return;
        }
        if (newPassword !== confirmPassword) {
            setOtpError("Passwords don’t match. Please try again.")
            return;
        }
        const body = {
            "mobile":formData.mobile.trim(),
            "otp":formData.otp.trim(),
            "password":formData.newPassword.trim(),
            "confirm_password":formData.confirmPassword.trim()
        }
        
        try {
            const response = await ResetPassword({ body });
            if (response.error){
                setOtpError("Otp doesn't match. Please try again.")
                return;
            }
            else{
                if (response.data.resp_code === 1){
                    setOtpError("")
                    onComplete();
                }
                else{
                    setOtpError("Otp doesn't match. Please try again.")
                }
            }
            
        } catch (error) {
            console.error("Error verifying OTP:", error);
            setOtpError("Something went wrong. Please try again.");
        }
    };


    // const handleResetPassword = async () => {
    //     const { newPassword, confirmPassword } = formData;
    //     if (newPassword !== confirmPassword) {
    //     alert("Passwords do not match!");
    //     return;
    //     }
    //     try {
    //         const response = await onSubmit({
    //             mobile: formData.mobile,
    //             newPassword: formData.newPassword,
    //         });
    //         if (response.success) {
    //             alert("Password reset successfully!");
    //             onClose(); // Close the popup
    //         } else {
    //             alert("Error resetting password. Please try again.");
    //         }
    //     } catch (error) {
    //         console.error("Error resetting password:", error);
    //     }
    // };

    return (
    <div className="login-popup-overlay" onClick={handleOverlayClick}>
        <div className="register-pop-up">
            <div className="register-head-section">
            <PopUpMainHeading content={"Reset Your Account Password"} />
            <PopUpSubHeading
                content={
                step === 1
                    ? "Enter your registered mobile number to receive an OTP."
                    : "Enter the following credentials to reset your account."
                    // ? "Enter the OTP sent to your registered mobile number."
                    // : "Set your new password."
                }
            />
        </div>
        <form className="login-input-button-section">
            {otpError && <ErrorMessage message={otpError}/>}
            {step === 1 && (
            <div className="input-field-section">
                <InputTextField
                    htmlFor={"mobile"}
                    content={"Registered Mobile"}
                    name={"mobile"}
                    value={formData.mobile}
                    onChange={handleChange}
                    type="mobile"
                />
                {mobileError && <ErrorMessage message={mobileError}/>}
                <div className="button-padding"></div>
                <PrimarySolidButton label={"Send OTP"} onClick={handleSendOtp} />
            </div>
            )}
            {step === 2 && (
            <div className="input-field-section">
                {/* {otpError && <p className="error-message">{otpError}</p>} */}
                <InputTextField
                    htmlFor={"otp"}
                    content={"Enter OTP"}
                    name={"otp"}
                    value={formData.otp}
                    onChange={handleChange}
                />
                <PasswordInput
                    htmlFor={"newPassword"}
                    content={"New Password"}
                    name={"newPassword"}
                    value={formData.newPassword}
                    onChange={handleChange}
                    type="password"
                    hasPhoneIcon={false}
                />
                <PasswordInput
                    htmlFor={"confirmPassword"}
                    content={"Confirm Password"}
                    name={"confirmPassword"}
                    value={formData.confirmPassword}
                    onChange={handleChange}
                    type="password"
                    hasPhoneIcon={false}
                />
                <div className="button-padding"></div>
                <PrimarySolidButton label={"Submit"} onClick={handleResetAccount} />
            </div>
            )}
            {/* {step === 3 && (
            <div className="input-field-section">
                
                <PrimarySolidButton label={"Reset Password"} onClick={handleResetPassword} />
            </div>
            )} */}
        </form>
        </div>
    </div>
    );
};

export default ForgotPassword;
