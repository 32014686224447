import React,{useState,useEffect, useContext} from "react";
import { useLocation } from "react-router-dom";
import { AuthContext } from "../../services/AuthContext";
// components
import NavBar from "../../components/NavBar/NavBar";
import NavBarMain from "../../components/NavBarMain/NavBarMain";
import Footer from "../../components/Footer/Footer";
import CategoryAndSrviceTab from "../../components/CategoryAndServiceTab/CategoryAndServiceTab";
import SecondarySearchBox from "../../components/Search/SecondarySearch/SecondarySearch";
import Pagination from "../../components/Pagination/Pagination";
// css
import '../../Common.css';
import './services.css'

const Services = ()=>{
    const location = useLocation();
    const [activeTab, setActiveTab] = useState(location.state?.tab||"categories"); 
    const [searchValue, setSearchValue] = useState(""); 
    const {isAuthenticated} = useContext(AuthContext);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);


    useEffect(() => {
        const tab = location.state?.tab;
        if (tab) {
            setActiveTab(tab); 
        }
    }, [location.state]);

    useEffect(()=>{
        setCurrentPage(1);
    },[activeTab])

    const getPlaceholder = () => {
        return activeTab === "categories"
            ? "Search Categories"
            : "Search Services";
    };

    const handleSearchSubmit = (value) => {
        setSearchValue(value); // Update search value
    };
    return(
        <div className="body-container">
            <div>
            {isAuthenticated?<NavBarMain/>:
            <NavBar/> }
                <div className="page-title-box">
                    <div className="page-title">
                        Our Services
                    </div>
                    <div className="page-text-content">
                        Discover a range of convenient services designed to meet your needs and
                        make everyday life simpler
                    </div>
                </div>
                <div className="service-search-container">
                    <div className="service-seach-box">
                        <SecondarySearchBox
                            placeholder={getPlaceholder()}
                            onSubmit={handleSearchSubmit}
                        />
                    </div>
                </div>
                <div className="category-service-container">
                    <CategoryAndSrviceTab
                        activeClassName = 'selected'
                        tab={activeTab}
                        search={searchValue}
                        onTabChange={setActiveTab}
                        currentPage = {currentPage}
                        setTotalPages = {setTotalPages}
                    />
                </div>
                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={setCurrentPage}
                />
            </div>
            <Footer/>
        </div>
    )
};

export default Services;