import React from "react";
import { useContext, useEffect,useState } from "react";
import './LocationForm.css';
import moment from 'moment';

// components
import PrimarySolidButton from "../../Buttons/PrimarySolidButton/PrimarySolidButton";
import PopUpMainHeading from "../../Heading/PopUpMainHeading/PopUpMainHeading";
import AccountWarning from "../TextItems/AccountWarning/AccountWarning";
import PopUpSubHeading from "../../Heading/PopUpSubHeading/PopUpSubHeading";
import InputTextField from "../../InputFields/InputTextField/InputTextField";
import ErrorMessage from "../../ErrorMessage/ErrorMessage";

const LocationForm2 = ({onClose,onInputChange,onSubmit,onLoginNow,errorMessage,onBackButtonClick,formData}) =>{

    const handleOverlayClick = (e) =>{
        if(e.target.className === 'login-popup-overlay'){
            onClose();
        };
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        onInputChange(name, value);
    };

    return (
        <div className="login-popup-overlay" onClick={handleOverlayClick}>
            <div className="register-pop-up">
                <div className="register-head-section">
                    <PopUpMainHeading content={"Enter Location"}/>
                    <PopUpSubHeading content={"Enter your location details so we can reach you easily!"}/>
                </div>
                <div className="back-button-box" onClick={onBackButtonClick}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M12 19L5 12L12 5" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M19 12H5" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
                <form className="login-input-button-section">
                    {errorMessage && <ErrorMessage message={errorMessage}/>}
                    <div className="input-field-section">
                        <InputTextField htmlFor={"country"} onChange={handleInputChange} name={"country"}  content={'Country'} value={formData.country} required={true} />
                        <InputTextField htmlFor={"state"} onChange={handleInputChange} name={"state"} content={'State/Province/Region'} value={formData.state} required={true} />
                        <div className="half-width-input gap-62">
                            <InputTextField htmlFor={"street1"} onChange={handleInputChange} name={"street1"} content={'Street 1'} value={formData.street1} required={true} />
                            <InputTextField htmlFor={"street2"} onChange={handleInputChange} name={"street2"} content={'Street 2'} value={formData.street2} />
                        </div>
                        <InputTextField htmlFor={"housenumber"} onChange={handleInputChange} name={"building"} content={'House/Building Number/Apartment'} value={formData.building} required={true}/>
                        <div className="half-width-input gap-62">
                            <InputTextField htmlFor={"city"} onChange={handleInputChange} name={"city"} content={'City'} value={formData.city} required={true} />
                            <InputTextField htmlFor={"zipcode"} onChange={handleInputChange} name={"zip"} content={'Zipcode'} value={formData.zip} required={true} />
                        </div>
                    </div>
                    
                    <div className={`forgot-already-account-box margin-new`}>
                        <AccountWarning content={"Already have an account?"}
                        link={"Log in now"} onClick={onLoginNow}/>
                        
                        <PrimarySolidButton label={"Continue"} onClick={onSubmit} />  
                    </div>
                </form>
            </div>
        </div>
    )
}
export default LocationForm2;