import React,{useContext} from "react";
import './CustomerSupport.css';

import { AuthContext } from "../../services/AuthContext";

// components
import NavBarAR from "../../components/NavBar/NavBarAR";
import NavBarMainAR from "../../components/NavBarMain/NavBarMainAR";
import FooterAR from "../../components/Footer/FooterAR";
import ContactCard from "../../components/ContactCard/ContactCard";
// images
import phone from '../../assets/phone.svg';
import flag from '../../assets/flag.png';
import mail from '../../assets/mail.svg';

const CustomerSupportAR = ()=>{
    const {isAuthenticated} = useContext(AuthContext);

    return(
        <div className="body-container">
            <div>
                {isAuthenticated?<NavBarMainAR/>:
                <NavBarAR/> }
                <div className="page-title-box">
                    <div className="page-title">
                        اتصل بنا    
                    </div>
                </div>
                <div className="support-description">
                    <span>
                    هل تحتاج إلى مساعدة؟ نحن هنا لمساعدتك! إذا كانت لديك أي أسئلة أو مخاوف أو تحتاج إلى دعم فيما يتعلق بطلباتك، فإن فريقنا مستعد دائمًا للمساعدة. نسعى جاهدين لتوفير تجربة سلسة وخالية من المتاعب، وضمان تلبية جميع احتياجاتك بكفاءة. لا تتردد في التواصل معنا في أي وقت للحصول على المساعدة.
                    </span>
                </div>
                <div className="contact-card-wrapper arabic-grid">
                    <div className="flag-address-box">
                        <div className="flag-image">
                            <img src={flag} alt="" />
                        </div>
                        <div className="office-address office-address-ar">
                            <span>٩٧٩٠٥ شارع كوفاتشيك، حي الجبل الأخضر، مدينة أبيرناثي، محافظة النور ٢٠٥٠٩ ٩٧٩٠٥ شارع كوفاتشيك، حي الجبل الأخضر، مدينة أبيرناثي، محافظة النور ٢٠٥٠٩</span>
                        </div>
                    </div>
                    <ContactCard 
                        image={phone} 
                        heading={"استفسار عام"} 
                        content={["+9662797823987","+966506642273"]}  
                    />
                    <ContactCard 
                        image={mail} 
                        heading={"بريد الدعم"} 
                        content={["info@Ultra-waves.com"]}  
                    />

                </div>

            </div>
            <FooterAR/>
        </div>
    )
}
export default CustomerSupportAR;