import React, { useContext, useState } from "react";
import "./LanguageSwitch.css";
import { AuthContext } from "../../services/AuthContext";

const LanguageSwitch2 = () => {
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem("language") || "en");
    const {setLanguage} = useContext(AuthContext)

    const togglePopup = () => {
        setIsPopupOpen(!isPopupOpen);
    };

    const handleLanguageChange = (language) => {
        setSelectedLanguage(language);
        setIsPopupOpen(false);
        setLanguage(language);
        localStorage.setItem('language',language);
    };

    return (
        <div className="language-switch-container">
        {/* Language Icon */}
        <div className={`language-icon ${selectedLanguage === "en"?"":"arabic-flex"}`}  onClick={togglePopup}>
            <div>
                {selectedLanguage === "en" ? (
                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                    <mask id="mask0_2030_614" maskUnits="userSpaceOnUse" x="0" y="0" width="21" height="21">
                        <circle cx="10.5" cy="10.5" r="10.5" fill="#D9D9D9" />
                    </mask>
                    <g mask="url(#mask0_2030_614)">
                        <path d="M-5 0H23V21H-5" fill="#BD3D44" />
                        <path d="M-5 2.41937H23H-5ZM-5 5.64375H23H-5ZM-5 8.88125H23H-5ZM-5 12.1187H23H-5ZM-5 15.3562H23H-5ZM-5 18.5937H23H-5Z" fill="black" />
                        <path d="M-5 2.41937H23M-5 5.64375H23M-5 8.88125H23M-5 12.1187H23M-5 15.3562H23M-5 18.5937H23" stroke="white" strokeWidth="1.46458" />
                        <path d="M-5 0H10.96V11.3094H-5" fill="#192F5D" />
                    </g>
                    </svg>
                ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                    <circle cx="10.5" cy="10.5" r="10.5" fill="#D9D9D9" />
                    <path fill="#007A3D" d="M0 0h21v21H0z" />
                    <path fill="#FFFFFF" d="M10.5 6.3l1.8 5.4H8.7l1.8-5.4z" />
                    <circle cx="10.5" cy="10.5" r="2.5" stroke="white" strokeWidth="1" />
                    </svg>
                )}
            </div>
            <div>
                {selectedLanguage === "en" ? "Eng" : "Arb"}
            </div>
            <div className="language-arrow-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                    <path d="M3 4.5L6 7.5L9 4.5" stroke="#241E20" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
        </div>

        {/* Language Popup */}
        {isPopupOpen && (
            <div className="language-popup">
            <div
                className="language-option"
                onClick={() => handleLanguageChange("en")}
            >
                English
            </div>
            <div
                className="language-option"
                onClick={() => handleLanguageChange("ar")}
            >
                العربية
            </div>
            </div>
        )}
        </div>
    );
    };

export default LanguageSwitch2;