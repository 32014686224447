import ApiConfig from './ApiConfig.js';
import {baseUrl} from './BaseUrl.js';


export const ServiceCategoryAPI = async ({search})=>{
    return await ApiConfig('get',`${baseUrl}/service/category/?search=${search}`)
}
export const ServiceAPI = async ({body,search})=>{
    return await ApiConfig('get',`${baseUrl}/service/?search=${search}`,null,body)
}
export const TokenApi = async ({body})=>{
    return await ApiConfig('post',`${baseUrl}/accounts/token/`,null,body)
}
export const ServicesListView = async ({params})=>{
    return await ApiConfig('get',`${baseUrl}/service/?${params}`,null,null)
}
export const ServiceDetailAPI = async (id)=>{
    return await ApiConfig('get',`${baseUrl}/service/${id}`)
}
// Single Category details
export const SingleServiceCategoryAPI = async ({params})=>{
    return await ApiConfig('get',`${baseUrl}/service/category/${params}/`)
}
// refresh access token
export const RefreshAccessToken = async ({body})=>{
    return await ApiConfig('POST',`${baseUrl}/accounts/token/refresh/`)
}
// my-profile api
export const PersonalProfileAPI = async ({body,headers})=>{
    return await ApiConfig('GET',`${baseUrl}/customer/my-profile/`,headers,body)
}
// Update My Profile
export const UpdateProfileAPI = async ({body,headers})=>{
    return await ApiConfig('PUT',`${baseUrl}/customer/my-profile/update/`,headers,body)
}
// create service request
export const CreateServiceRequest = async ({body,headers})=>{
    return await ApiConfig('POST',`${baseUrl}/request/create/`,headers,body)
}
// edit service request
export const EditServiceRequest = async ({body,headers,params})=>{
    return await ApiConfig('PUT',`${baseUrl}/request/update/${params}/`,headers,body)
}
// list bookings
export const ListBookings = async ({body,headers,params})=>{
    return await ApiConfig('GET',`${baseUrl}/request/?${params}`,headers,body)
}
// cancel booking
export const CancelBooking = async ({body,headers,params})=>{
    return await ApiConfig('PUT',`${baseUrl}/request/cancel/${params}/`,headers,body)
}
// Booking Detail View
export const BookingDetail = async ({body,headers,params})=>{
    return await ApiConfig('GET',`${baseUrl}/request/${params}/`,headers,body)
}
// Notificaiton Unread count
export const NotificaitonCount = async ({body,headers})=>{
    return await ApiConfig('GET',`${baseUrl}/notifications/unread-count/`,headers,body)
}
//  All Notifications
export const AllNotifications = async ({body,headers,params})=>{
    return await ApiConfig('GET',`${baseUrl}/notifications/all/${params}`,headers,body)
}
//  Log Out
export const LogOutUser = async ({body,headers})=>{
    return await ApiConfig('POST',`${baseUrl}/accounts/logout/`,headers,body)
}
//  Send OTP
export const SendOTP = async ({body,headers})=>{
    return await ApiConfig('POST',`${baseUrl}/accounts/send-otp/`,headers,body)
}
//  Verify OTP
export const VerifyOTP = async ({body,headers})=>{
    return await ApiConfig('POST',`${baseUrl}/accounts/verify-otp/`,headers,body)
}
//  Register User
export const RegisterUser = async ({body,headers})=>{
    return await ApiConfig('POST',`${baseUrl}/customer/register/`,headers,body)
}
//  Reset Password
export const ResetPassword = async ({body,headers})=>{
    return await ApiConfig('POST',`${baseUrl}/accounts/forgot-password/`,headers,body)
}
//  Check User Already exists
export const UserExists = async ({body})=>{
    return await ApiConfig('POST',`${baseUrl}/customer/check-user-validity/`,null,body)
}
//  Delete account
export const DeleteAccountAPI = async ({body,headers})=>{
    return await ApiConfig('GET',`${baseUrl}/accounts/delete-my-account/`,headers,body)
}


