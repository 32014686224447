import React,{useState,useContext,useEffect} from "react";
import '../../Common.css';
import './NavBar.css';
import { useNavigate,useLocation } from "react-router-dom";
import { AuthContext } from "../../services/AuthContext";
// Components
import PrimarySolidButton from "../Buttons/PrimarySolidButton/PrimarySolidButton";
import PrimaryStrokeButton from "../Buttons/PrimaryStrokeButton/PrimaryStrokeButton";
import TabSwitch from "../InputFields/TabSwitch/TabSwitch";
import LanguageSwitch2 from "../ChangeLanguage/LanguageSwitch2";
import LoginAR from "../PopUps/LogIn/LoginAR";
import CreateAccountAR from "../PopUps/CreateAccount/CreateAccountAR";
import LocationForm2AR from "../PopUps/LocationForm/LocationForm2AR";
import OtpFormAR from "../PopUps/OtpForm/OtpFormAR";
import ForgotPasswordAR from "../PopUps/ForgotPassword/ForgotPasswordAR";
import ScuccessPopUp from "../PopUps/AccountCreationSuccess/ScuccessPopUp";
import ShowLogPopUpAR from "../PopUps/ShowLogRegPopUP/ShowLogPopUpAR";
import { LocationValidatorAR } from "../../services/Utilities";
// API's
import { SendOTP,VerifyOTP,RegisterUser,UserExists } from "../../services/Api";
// images
import navlogo from '../../assets/navbar_logo.png';

const NavBarAR = ()=>{
    const location = useLocation();
    const routeToTabMapping = {
            "/ar/": "home",
            "/ar/help-support": "help-support",
        };
    
    useEffect(() => {
            const currentTab = routeToTabMapping[location.pathname] || "";
            setSelectedValue(currentTab);
        }, [location.pathname]);

    const [selectedValue, setSelectedValue] = useState("home");
    const navigate = useNavigate();
    const handleSelection = (value,route) => {
        setSelectedValue(value);
        navigate(route);
    };

    // 

    const [isLoginPopupOpen, setIsLoginPopupOpen] = useState(false);
    const [isRegisterPopup,setIsRegisterPopup] = useState(false)
    const [isLocationPopup,setIsLocationPopup] = useState(false);
    const [isOtpPopup,setIsOtpPopup] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [regSuccess,setRegSuccess] = useState(false);
    const [resetSuccess,setResetSuccess] = useState(false);
    const [forgotPass,setForgotPass] = useState(false);
    const [verifyOptError,setVerifyError] = useState("");
    const [searchValue,setSearchValue] = useState("");
    const {setLoginToBook,loginTOBook,setShowLoginSuccessPopup} = useContext(AuthContext)
    const [showLogPop,setShowLogPop] = useState(false);
    const [errorMessage,setErrorMessage] = useState("");

    const [registerFormData, setRegisterFormData] = useState({
            firstname: "",
            lastname: "",
            mobile: "",
            email: "",
            createpass: "",
            confirmpass: "",
            country: "",
            country_code:"966",
            state: "",
            street1: "",
            street2: "",
            building: "",
            city: "",
            zip: "",
            otp:"",
            termsAccepted: false,
        });

    const [forgotPasswordData, setForgotPasswordData] = useState({
            otp:"",
            mobile: "",
            newPassword: "",
            confirmPassword: "",
        });

    useEffect(() => {
        if (loginTOBook) {
            toggleShowLogPopUp();
            setLoginToBook(false);
        }
    }, [loginTOBook]);

    const manageLoginPopUp =()=>{
        toggleLoginPopup();
    }

    const toggleLoginPopup = () => {
        setIsLoginPopupOpen(!isLoginPopupOpen);
    };
    const toggleRegisterPopUp = () =>{
        setIsRegisterPopup(!isRegisterPopup);
    }
    const handleLoginSuccess = () => {
        setShowLoginSuccessPopup(true);
        // Hide the success popup after 3 seconds
        setTimeout(() => {
            setShowLoginSuccessPopup(false);
            navigate('/');
            setIsLoggedIn(true);
        }, 3000);
    };
    const handleUnauthenticated = () =>{
        toggleLoginPopup();
    }

    const handleStateOnLogout = () =>{
        setIsLoggedIn(false);
    }
    const handleHaveAccount = () =>{
        setIsRegisterPopup(!isRegisterPopup);
        toggleLoginPopup();
    }
    const handleHaveAccountFromLocation = () =>{
        toggleLocationPopup();
        toggleLoginPopup();
    }
    const handleHaveAccountFromVerifyOtp = () =>{
        toggleOtpPopup();
        toggleLoginPopup();
    }
    const handleCreateAccount = () =>{
        handleHaveAccount();
    }
    const toggleLocationPopup = () =>{
        setIsLocationPopup(!isLocationPopup)
    }
    const toggleOtpPopup = () =>{
        setIsOtpPopup(!isOtpPopup);
    }
    const toggleRegSuccessPopup =() =>{
        setRegSuccess(!regSuccess)
    }
    const toggleForgotPassPopUp =() =>{
        setForgotPass(!forgotPass)
    }
    const toggleResetSuccessPopUP = () =>{
        setResetSuccess(!resetSuccess);
    }
    const toggleShowLogPopUp = () =>{
        setShowLogPop(!showLogPop);
    }

    // Forgot password Section
    const handleForgotPasswordInputChange = (field, value) => {
        setForgotPasswordData((prevData) => ({
            ...prevData,
            [field]: value,
        }));
    };

    const openForgotPassordPopUp = () =>{
        toggleLoginPopup();
        toggleForgotPassPopUp();
    }

    const handleResetPassSuccess = () =>{
        toggleForgotPassPopUp();
        toggleResetSuccessPopUP();
        setTimeout(() => {
            setResetSuccess(false);
            toggleLoginPopup();
        }, 3000);
    }

    // Section for saving  the Registration form datas
    const handleRegisterInputChange = (field, value) => {
        setRegisterFormData((prevData) => ({
            ...prevData,
            [field]: value,
        }));
    };

    const handleCheckboxChange = (event) => {
        setRegisterFormData((prevData) => ({
            ...prevData,
            termsAccepted: event.target.checked,
        }));
    };

    const backToRegistration = ()=>{
        toggleLocationPopup();
        setIsRegisterPopup(!isRegisterPopup);
    }
    const backToLocation =()=>{
        toggleOtpPopup();
        setIsLocationPopup(!isLocationPopup);
    }

    const handleRegisterSubmit = async() => {
        const { createpass, confirmpass, email,mobile,firstname,country_code } = registerFormData;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        setErrorMessage("");

        if(!firstname.trim().length>0){
            setErrorMessage("لا يمكن أن يكون الاسم الأول فارغاً")
            return;
        }
        if(!country_code.trim().length>0){
            setErrorMessage("حدد رمز بلدك للمتابعة")
            return;
        }
        if (!mobile.trim().length>0){
            setErrorMessage("أدخل هاتفك المحمول للمتابعة")
            return;
        }
        if (!emailRegex.test(email.trim())) {
            setErrorMessage("تنسيق البريد الإلكتروني غير صالح!")
            return;
        }
        if (createpass !== confirmpass) {
            setErrorMessage("كلمات المرور غير متطابقة!")
            return;
        }
        else if(!createpass.trim().length>0 |  !confirmpass.trim().length>0){
            setErrorMessage("لا يمكن أن تكون كلمات المرور فارغة")
            return;
        }
        if (!registerFormData.termsAccepted) {
            setErrorMessage("يرجى التحقق من الشروط والأحكام للمتابعة");
            return;
        }
        const body = {
            "mobile":mobile,
            "email":email
        }

        const response = await UserExists({body})
        if(response.data.email_exists){
            setErrorMessage("البريد الإلكتروني موجود بالفعل. حاول استخدام بريد آخر.")
            return;
        }
        else if(response.data.mobile_exists){
            setErrorMessage("الجوال موجود بالفعل.جرب هاتفًا آخر.")
            return;
        }

        toggleRegisterPopUp();
        toggleLocationPopup();

    };

    const handleLocationSubmit = async() =>{
        setErrorMessage("");
        const { country,state,street1,building,zip,city } = registerFormData;
        const fields = {"دولة":country,"ولاية":state,"الشارع 1":street1,"مبنى":building,"مدينة":city,"أَزِيز":zip}
        const message = LocationValidatorAR({fields})
        if (message.length>0){
            setErrorMessage(message);
            return
        }
        const body={
            "mobile":registerFormData.mobile,
            "country_code":registerFormData.country_code
        }
        try{
            const response = await SendOTP({body})
            if (response.data.resp_code == 0){
                setErrorMessage(response.data.message);
                return;
            }
            else{
                if (response.data.resp_code === 1){
                    toggleOtpPopup();
                    toggleLocationPopup();
                }
                else{
                    console.error("error sending otp")
                }
            }
        }
        catch(error){
            console.log("error sending otp",error)
        }
        

    }

    const handleVerifyOtp = async() =>{
        const body = {
            "mobile":registerFormData.mobile,
            "otp":registerFormData.otp
        }

        try{
            const response = await VerifyOTP({body})
            if (response.error){
                console.log(response)
                setVerifyError("Otp doesn't match. Please try again.")
                return;
            }
            else{
                if (response.data.resp_code === 1){
                    setVerifyError("")
                    // Register the user
                    registerUser();
                }
                else{
                    setVerifyError("Otp doesn't match. Please try again.")
                }
            }
        }
        catch(error){
            console.log("error sending otp",error)
        }
    }

    const registerUser = async() =>{
        const body ={
            "first_name":registerFormData.firstname,
            "last_name":registerFormData.lastname,
            "mobile":registerFormData.mobile,
            "email":registerFormData.email,
            "password":registerFormData.confirmpass,
            "country":registerFormData.country,
            "country_code":registerFormData.country_code,
            "state":registerFormData.state,
            "street1":registerFormData.street1,
            "street2":registerFormData.street2,
            "building":registerFormData.building,
            "city":registerFormData.city,
            "zip":registerFormData.zip
        }
        try{
            const response = await RegisterUser({body})
            if (response.error){
                console.log("error registering the user")
                // setVerifyError("Otp doesn't match. Please try again.")
                return;
            }
            else{
                console.log(response)
                if (response.data.resp_code === 1){
                    toggleOtpPopup();
                    toggleRegSuccessPopup();
                    setTimeout(() => {
                        setRegSuccess(false);
                        toggleLoginPopup();

                    }, 2000);
                }
                else{
                    // setVerifyError("Otp doesn't match. Please try again.")
                    console.log("error")
                }
            }
        }
        catch(error){
            console.log("error sending otp",error)
        }
    }
    const onRegisterClick = () =>{
        toggleRegisterPopUp();
    }

    const handleLoginClick =() =>{
        toggleLoginPopup();
        setShowLogPop(false);

    }
    const handleRegisterClick =() =>{
        toggleRegisterPopUp();
        setShowLogPop(false);

    }
    // 


    const [harmburgerState,setHarmburgerState] = useState(false);
        const [screenSize, setScreenSize] = useState(window.innerWidth);
    
        // To toggle the navbar items in small screens
        const handleHarburgerClick = ()=>{
            setHarmburgerState(!harmburgerState)
        }
    
        useEffect(() => {
            const handleResize = () => {
                setScreenSize(window.innerWidth);
            }
            window.addEventListener("resize", handleResize);
            return () => {
                window.removeEventListener("resize", handleResize);
            };
        });


    
    return (
        <div className="nav-container same-v-line">
            <div className="nav-inner-container nav-inner-container-ar">
                <div className="nav-logo-img" onClick={() => navigate("/ar/")}>
                    <img src={navlogo} alt="Navbar logo"/>
                </div>
                {(harmburgerState || screenSize > 992) && (
                <div className="nav-right-section nav-right-section-ar">
                        <div className="tab-section tab-section-ar">

                            <TabSwitch
                            key={"home"}
                            content={"بيت"}
                            name={"sub-nav-bar-tabs"}
                            id={"home"}
                            htmlFor={"home"}
                            selectedValue={selectedValue}
                            onSelection={() => handleSelection("home","/ar/")} 
                            />

                            <TabSwitch
                            key={"help-support"}
                            content={"هنا للمساعدة، في كل خطوة!"}
                            name={"sub-nav-bar-tabs"}
                            id={"help-support"}
                            htmlFor={"help-support"}
                            selectedValue={selectedValue}
                            onSelection={() => handleSelection("help-support","/ar/help-support")} 
                            />

                            <LanguageSwitch2/>
                        </div>
                        <div className="login-reg-button-box login-reg-button-box-ar">
                            <PrimaryStrokeButton label="تسجيل الدخول" onClick={manageLoginPopUp} />
                            <PrimarySolidButton label="إنشاء حساب" onClick={onRegisterClick}/>
                        </div>
                </div>
                )}
                
            </div>
            <i class="harmburger-icon fa-solid fa-bars" onClick={handleHarburgerClick}></i>

            {isLoginPopupOpen && <LoginAR onClose={toggleLoginPopup} onLoginSuccess={handleLoginSuccess}
                onCreateAccnt={handleCreateAccount} onForgotPass={openForgotPassordPopUp} />}
            {isRegisterPopup &&  <CreateAccountAR 
                onClose={toggleRegisterPopUp} onLoginNow={handleHaveAccount}
                onInputChange={handleRegisterInputChange} onSubmit={handleRegisterSubmit}
                errorMessage = {errorMessage} checkboxChecked={registerFormData.termsAccepted}
                onCheckboxChange={handleCheckboxChange}
                formData={registerFormData}
                setFormData={setRegisterFormData}
            /> } 
            {isLocationPopup && 
                <LocationForm2AR
                    onClose={toggleLocationPopup} 
                    onInputChange={handleRegisterInputChange} 
                    onSubmit={handleLocationSubmit} 
                    onLoginNow={handleHaveAccountFromLocation}
                    errorMessage = {errorMessage}
                    onBackButtonClick = {backToRegistration}
                    formData={registerFormData}
                />
            }
            {isOtpPopup && 
                <OtpFormAR 
                    error={verifyOptError} 
                    onSubmit={handleVerifyOtp} 
                    onInputChange={handleRegisterInputChange} 
                    onLoginNow={handleHaveAccountFromVerifyOtp}
                    onClose={toggleOtpPopup}
                    onBackButtonClick = {backToLocation}
                />
            }
            {regSuccess && <ScuccessPopUp message={"تم إنشاء الحساب بنجاح"}/>}
            {resetSuccess && <ScuccessPopUp message={"تمت إعادة ضبط الحساب بنجاح"}/>}
            {forgotPass && 
                <ForgotPasswordAR 
                    onClose={toggleForgotPassPopUp}
                    onInputChange={handleForgotPasswordInputChange}
                    onComplete = {handleResetPassSuccess}
                />
            }
            {showLogPop && <ShowLogPopUpAR 
                onClose={toggleShowLogPopUp}
                onLoginClick={handleLoginClick}
                onRegisterClick={handleRegisterClick}
            />}
        </div>
    )
}


export default NavBarAR