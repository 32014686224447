import React,{useState,useEffect,useContext} from "react";
import '../Common.css';
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../services/AuthContext";

// Cpmponents
import NavBar from "../components/NavBar/NavBar";
import NavBarMain from "../components/NavBarMain/NavBarMain";
import Banner from "../components/Banner/Banner";
import MainHeading from "../components/Heading/MainHeading/MainHeading";
import CategoryAndSrviceTab from "../components/CategoryAndServiceTab/CategoryAndServiceTab";
import Footer from "../components/Footer/Footer";
import PrimarySolidButton from "../components/Buttons/PrimarySolidButton/PrimarySolidButton";
const Home = ()=>{
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [searchValue,setSearchValue] = useState("");
    const [selectedTab,setSelectedTab] = useState("categories");
    const { logout,isAuthenticated } = useContext(AuthContext);
    const navigate = useNavigate();


    const handleViewAllClick = ()=>{
        const tab = selectedTab; 
        navigate('/services', { state: { tab } });
    }

    useEffect(() => {
        const checkAuthToken = () => {
            const accessToken = localStorage.getItem("authToken");
            const refreshToken = localStorage.getItem("refreshToken");
    
            if (accessToken && refreshToken) {
                setIsLoggedIn(true);
            } else {
                setIsLoggedIn(false);
            }
        };
        checkAuthToken();
    }, []);


    const handleStateOnLogout = () =>{
        setIsLoggedIn(false);
    }

    function submitSearch(value){
        setSearchValue(value);
        setSelectedTab("services");
    }   
    
    return(
        <div className="body-container">
            <div>
            {isAuthenticated?<NavBarMain/>:
            <NavBar/> 
            }
            <Banner onSubmit={submitSearch} />
            <div className="content-section">
                <MainHeading heading="Explore Our services"/>
                <div className="heading-description">
                    Explore our wide range of services, available at your fingertips. Fast, easy,
                    and reliable help when you need it!
                </div>

                <CategoryAndSrviceTab 
                    search={selectedTab === "categories" ? "" : searchValue} 
                    tab={selectedTab} 
                    onTabChange={setSelectedTab}
                />
            </div>
            <div className="primary-button-box">
                <PrimarySolidButton label="View All" onClick={handleViewAllClick}/>
            </div>
            </div>
            <Footer/>
        </div>
    )
}

export default Home;