import React,{useState,useEffect,useContext} from "react";
import '../Common.css';
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../services/AuthContext";

// Cpmponents
import NavBarAR from "../components/NavBar/NavBarAR";
import NavBarMainAR from "../components/NavBarMain/NavBarMainAR";
import BannerAR from "../components/Banner/BannerAR";
import MainHeading from "../components/Heading/MainHeading/MainHeading";
import CategoryAndSrviceTabAR from "../components/CategoryAndServiceTab/CategoryAndServiceTabAR";
import FooterAR from "../components/Footer/FooterAR";
import PrimarySolidButton from "../components/Buttons/PrimarySolidButton/PrimarySolidButton";

const HomeAR = ()=>{
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [searchValue,setSearchValue] = useState("");
    const [selectedTab,setSelectedTab] = useState("categories");
    const { logout,isAuthenticated } = useContext(AuthContext);
    const navigate = useNavigate();

    const handleViewAllClick = ()=>{
        const tab = selectedTab; 
        navigate('/ar/services', { state: { tab } });
    }

    useEffect(() => {
        const checkAuthToken = () => {
            const accessToken = localStorage.getItem("authToken");
            const refreshToken = localStorage.getItem("refreshToken");
    
            if (accessToken && refreshToken) {
                setIsLoggedIn(true);
            } else {
                setIsLoggedIn(false);
            }
        };
        checkAuthToken();
    }, []);

    function submitSearch(value){
        setSearchValue(value);
        setSelectedTab("services")
    }   
    
    return(
        <div className="body-container">
            <div>
            {isAuthenticated?<NavBarMainAR/>:
            <NavBarAR/> 
            }
            <BannerAR onSubmit={submitSearch} />
            <div className="content-section">
                <MainHeading heading="استكشف خدماتنا"/>
                <div className="heading-description">
                اكتشف مجموعتنا الواسعة من الخدمات المتوفرة في متناول يدك. مساعدة سريعة وسهلة وموثوقة عندما تحتاج إليها!
                </div>

                <CategoryAndSrviceTabAR 
                    search={selectedTab === "categories" ? "" : searchValue} 
                    tab={selectedTab} 
                    onTabChange={setSelectedTab}
                />
            </div>
            <div className="primary-button-box">
                <PrimarySolidButton label="عرض الكل" onClick={handleViewAllClick}/>
            </div>
            </div>
            <FooterAR/>
        </div>
    )
}

export default HomeAR;