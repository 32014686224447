import React from "react";
import '../../Common.css'
import './BookService.css'
import { Navigate, useNavigate,useParams,useLocation } from "react-router-dom";
import { useContext, useEffect,useState } from "react";
import { ServiceDetailAPI } from "../../services/Api";
import { AuthContext } from "../../services/AuthContext";
import { PersonalProfileAPI,EditServiceRequest,EditLocation } from "../../services/Api";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from "dayjs";
import { BookingDetail } from "../../services/Api";
import moment from 'moment';
import { isToday,beforeToday,getNextTime } from "../../services/Utilities";

// components
import FooterAR from "../../components/Footer/FooterAR";
import NavBarMainAR from "../../components/NavBarMain/NavBarMainAR";
import ServiceListCardAR from "../../components/ServiceListCard/ServiceListCardAR";
import AddressCard from "../../components/Cards/AddressCard/AddressCard";
import TimeCard from "../../components/Cards/TimeCard/TimeCard";
import Calender from "../../components/Cards/DatePicker/DateCalender";
import PrimarySolidButton from "../../components/Buttons/PrimarySolidButton/PrimarySolidButton";
import PrimaryMutedButton from "../../components/Buttons/PrimaryMutedButton/PrimaryMutedButton";
import ServiceBookedPopUpAR from "../../components/PopUps/ServiceBookedPopUp/ServiceBookedPopUpAR";
import LocationFormAR from "../../components/PopUps/LocationForm/LocationFormAR";
import ErrorMessageAR from "../../components/ErrorMessage/ErrorMessageAR";
import { LocationValidatorAR } from "../../services/Utilities";

const EditServiceBookingAR = () =>{

    const {id} = useParams();
    const location = useLocation();
    const { bookingId,visit_date,visit_time } = location.state || {};
    const visiting_time = parseInt(visit_time.split(':')[0],10).toString();
    const [service,setService] = useState([]);
    const { fetchWithAuth } = useContext(AuthContext);
    const [address, setAddress] = useState();
    const [selectedDate, setSelectedDate] = useState(dayjs(visit_date));
    const [selectedTime,setSelectedTime] = useState(visiting_time);
    const [bookedText,setBookedText] = useState();
    const [locationError,setLocationError]= useState("");
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [isEditPopUp, setIsEditPopUp] = useState(false);
    const [popupData, setPopupData] = useState(null);
    const [erroMessage,setErrorMessage] = useState("");
    const navigate = useNavigate();
    const [profileData, setProfileData] = useState({
            country: '',
            street1: '',
            street2: '',
            building: '',
            city: '',
            state: '',
            zip: ''
        });
    
    const handleDateChange = (date) => {
        setSelectedDate(date);
        console.log(date.format("DD-MMM-YYYY"))
    }; 
    const handleTimeSelection = (time) => {
        // const formattedTime = time.toUpperCase();
        setSelectedTime(time);
    };

    useEffect(()=>{
        const fetchServiceDetail = async () =>{
            try{
                const response = await ServiceDetailAPI(id);
                setService(response.data.data);
            }
            catch (error) {
                console.error("Error fetching services:", error);
            } 
        };
        fetchServiceDetail();
    },[])

    useEffect(() => {
        const fetchBookingsDetails = async () => {
            const params = `${bookingId}`
            try {
                const response = await fetchWithAuth(BookingDetail,{params}); 
                
                if (response.error) {
                    console.error("Error fetching profile:", response.error);
                } else {
                    // const address = [
                    //     response.data.data.building || '', 
                    //     response.data.data.street1 || '', 
                    //     response.data.data.street2 || '', 
                    //     response.data.data.city || '', 
                    //     response.data.data.state || '', 
                    //     response.data.data.country || '', 
                    //     response.data.data.zip || ''
                    // ]
                    // .filter(part => part.trim() !== '') // Remove empty parts
                    // .join(', '); // Combine with a comma and space
    
                    // // Save the address to state
                    // setAddress(address);

                    setProfileData({
                        street1: response.data.data.street1 || '',
                        street2: response.data.data.street2 || '',
                        building: response.data.data.building || '',
                        city: response.data.data.city || '',
                        state: response.data.data.state || '',
                        zip: response.data.data.zip || '',
                        country:response.data.data.country || '',
                    });
                    
                    setSelectedDate(dayjs(response.data.data.visit_date));
                    // handleDateChange(dayjs(response.data.data.visit_date));
                }
            } catch (error) {
                console.error("Unexpected error:", error);
            }
        };

        fetchBookingsDetails();
    }, [bookingId]);

    useEffect(()=>{
        const formattedDate = selectedDate.format("DD-MMM-YYYY");
        const time = parseInt(selectedTime.trim(),10)
        const period = time < 12 ? "AM" : "PM";
        const formattedTime = time > 12 ? time - 12 : time;
        const displayTime = `${formattedTime} ${period}`;
        // setBookedText(`${formattedDate} ${displayTime} التاريخ المحدد`);
        setBookedText(`${formattedDate} ${displayTime} :التاريخ المحدد `);
    },[selectedDate,selectedTime])
    

    const convertToTimeFormat = (time) => {
        const hours = time < 10 ? `0${time}` : `${time}`;
        return `${hours}:00`;
    };

    const openPopup = (data) => {
        setPopupData(data); // Store data to be passed to the popup
        setPopupOpen(true); // Open the popup
    };
    const closePopup = () => {
        setPopupOpen(false);
    };
    const editServiceFunction = async() =>{
        if (beforeToday(selectedDate)){
            setErrorMessage("لا يمكنك الحجز لتواريخ سابقة. الرجاء اختيار تاريخ في المستقبل.")
            return;
        }
        if(isToday(selectedDate)&& parseInt(getNextTime(),10)>=18){
            setErrorMessage("الحجز لهذا اليوم مغلق. يرجى المحاولة ليوم غد.")
            return;
        }
        try{
            const params = bookingId;
            const body = {
                "visit_date":selectedDate.format("YYYY-MM-DD"),
                "visit_time":convertToTimeFormat(selectedTime),
                "note":"Service Request",
                "state":profileData.state,
                "city":profileData.city,
                "street1":profileData.street1,
                "street2":profileData.street2,
                "building":profileData.building,
                "zip":profileData.zip,
                "service":service.id,
                "country":profileData.country,

            }
            const response = await(fetchWithAuth(EditServiceRequest,{body,params}))
            
            const {data,error} = response.data;
            if (data){
                openPopup(data);
            }else if(error){
                console.error("Error in service request:", error);
            }
        }
        catch (error) {
            console.error("Unexpected error:", error);
        }
        
    }
    const continueBooking = () =>{
        navigate('/ar/service-bookings')
    }
    const cancelServiceBooking = () =>{
        navigate(-1)
    }
    const editAddress = (data) =>{
        setIsEditPopUp(true);
    }
    const closeEditForm = () =>{
        setIsEditPopUp(false);
    }
    const saveLocationDetails = async (updatedData) => {
        setLocationError("");
        const { country,state,street1,building,zip,city } = updatedData;
        const fields = {"country":country,"state":state,"street1":street1,"building":building,"city":city,"zip":zip}
        const message = LocationValidatorAR({fields})
        if (message.length>0){
            setLocationError(message);
            return
        }
        setProfileData({
            street1: updatedData.street1 || '',
            street2: updatedData.street2 || '',
            building: updatedData.building || '',
            city: updatedData.city || '',
            state: updatedData.state || '',
            zip: updatedData.zip || '',
            country:updatedData.country || '',
        });
        closeEditForm();
    }
    useEffect(()=>{
        const updateAddress = () =>{
            const address = [
                profileData.building || '', 
                profileData.street1 || '', 
                profileData.street2 || '', 
                profileData.city || '', 
                profileData.state || '', 
                profileData.country || '', 
                profileData.zip || ''
            ]
            .filter(part => part.trim() !== '') // Remove empty parts
            .join(', '); // Combine with a comma and space

            // Save the address to state
            setAddress(address);
        }
        updateAddress();
    },[profileData])

    return(
        <div className="body-container">
            <div>
                <NavBarMainAR/>
                <div className="page-title-box">
                    <div className="page-path">
                        <span>{service?.category?.name ? `${service.category.name} Service` : ""}</span>
                        <span>/</span>
                        <span>{service?.name?`${service?.name}` : ''}</span>
                    </div>
                    <div className="page-sub-title">
                        احجز خدمتك  
                    </div>
                </div>
                <div className="service-card-container-2">
                    <div className="service-booking-sub-container">
                        <div>
                            <ServiceListCardAR 
                            image={service.image}
                            heading ={service.name}
                            subHeading={service.description}
                            hasButton={false}
                            />
                        </div>
                        <div className="booking-cards-section">
                            <div className="container-section">
                                <div className="card-title card-title-ar">موقع</div>
                                {address && (
                                    <AddressCard 
                                    value={address}
                                    onEdit={()=>editAddress(profileData)}
                                    isArabic={true}
                                    />
                                )}
                                {isEditPopUp && <LocationFormAR data={profileData} onClose={closeEditForm}
                                onSave={saveLocationDetails} errorMessage ={locationError}/>}
                            </div>
                            <div className="container-section">
                                <div className="card-title card-title-ar">اختر التاريخ</div>
                                <Calender onChange={handleDateChange} mdate={selectedDate}/>
                            </div>
                            <div className="container-section">
                                <div className="card-title card-title-ar">حدد الوقت</div>
                                <TimeCard onSelection={handleTimeSelection} 
                                currentSelection={selectedTime}
                                selectedDate = {selectedDate.format("DD-MMM-YYYY")}
                                />
                            </div>
                        </div>
                        {erroMessage &&
                            <div className="error-message-section pt-1">
                                <ErrorMessageAR message={erroMessage}/>
                            </div>
                        }
                        <div className="confirm-button-section arabic-flex">
                            {bookedText && (
                                <div className="booked-date-text">
                                {bookedText}
                            </div>
                            )}
                            <div className="button-box arabic-flex">
                                <div className="cancel-button">
                                    <PrimaryMutedButton
                                        label={"يلغي"}
                                        onClick={cancelServiceBooking}
                                    />
                                </div>
                                <div className="confirm-button">
                                    <PrimarySolidButton 
                                        label={"تأكيد حجز الخدمة"}
                                        onClick={editServiceFunction}
                                    />
                                    {isPopupOpen && <ServiceBookedPopUpAR data={popupData} onClose={closePopup} 
                                    onSubmit={continueBooking} />}
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
            <FooterAR/>
        </div>
    )
}

export default EditServiceBookingAR;