import React, { useState } from "react";
import './CheckBox.css';

const CheckBox = ({ label, checked, onChange }) => {
    return (
        <label className="checkbox-container">
        <input type="checkbox" checked={checked} onChange={onChange} />
        {/* <span className="checkmark"></span> */}
        {label}
        </label>
    );
};

export default CheckBox;