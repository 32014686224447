import React,{useState,useEffect, useContext} from "react";
import '../../Common.css';
import './NavBar.css';
import { useNavigate,useLocation } from "react-router-dom";
import { AuthContext } from "../../services/AuthContext";
import { LocationValidator } from "../../services/Utilities";
// Components
import PrimarySolidButton from "../Buttons/PrimarySolidButton/PrimarySolidButton";
import PrimaryStrokeButton from "../Buttons/PrimaryStrokeButton/PrimaryStrokeButton";
import TabSwitch from "../InputFields/TabSwitch/TabSwitch";
import LanguageSwitch2 from "../ChangeLanguage/LanguageSwitch2";
import Login from "../PopUps/LogIn/Login";
import ForgotPassword from "../PopUps/ForgotPassword/ForgotPassword";
import ScuccessPopUp from "../PopUps/AccountCreationSuccess/ScuccessPopUp";
import OtpForm from "../PopUps/OtpForm/OtpForm";
import LocationForm2 from "../PopUps/LocationForm/LocationForm2";
import CreateAccount from "../PopUps/CreateAccount/CreateAccount";
import ShowLogPopUp from "../PopUps/ShowLogRegPopUP/ShowLogPopUp";
// api's
import { RegisterUser,VerifyOTP,SendOTP,UserExists } from "../../services/Api";


// images
import navlogo from '../../assets/navbar_logo.png';
const NavBar = ()=>{

    // 
    const [isLoginPopupOpen, setIsLoginPopupOpen] = useState(false);
    const [isRegisterPopup,setIsRegisterPopup] = useState(false)
    const [isLocationPopup,setIsLocationPopup] = useState(false);
    const [isOtpPopup,setIsOtpPopup] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [regSuccess,setRegSuccess] = useState(false);
    const [resetSuccess,setResetSuccess] = useState(false);
    const [forgotPass,setForgotPass] = useState(false);
    const [verifyOptError,setVerifyError] = useState("");
    const [searchValue,setSearchValue] = useState("");
    const {setLoginToBook,loginTOBook,setShowLoginSuccessPopup} = useContext(AuthContext)
    const [showLogPop,setShowLogPop] = useState(false);
    const [errorMessage,setErrorMessage] = useState("");
    const location = useLocation();

    const [registerFormData, setRegisterFormData] = useState({
            firstname: "",
            lastname: "",
            mobile: "",
            email: "",
            createpass: "",
            confirmpass: "",
            country: "",
            country_code:"966",
            state: "",
            street1: "",
            street2: "",
            building: "",
            city: "",
            zip: "",
            otp:"",
            termsAccepted: false,
        });

    const [forgotPasswordData, setForgotPasswordData] = useState({
            otp:"",
            mobile: "",
            newPassword: "",
            confirmPassword: "",
        });

    useEffect(() => {
        if (loginTOBook) {
            toggleShowLogPopUp();
            setLoginToBook(false);
        }
    }, [loginTOBook]);

    const manageLoginPopUp =()=>{
        toggleLoginPopup();

    }

    const toggleLoginPopup = () => {
        setIsLoginPopupOpen(!isLoginPopupOpen);
    };
    const toggleRegisterPopUp = () =>{
        setIsRegisterPopup(!isRegisterPopup);
    }
    const handleLoginSuccess = () => {
        setShowLoginSuccessPopup(true);// Show success popup
        // Hide the success popup after 3 seconds
        setTimeout(() => {
            setShowLoginSuccessPopup(false);
            navigate('/');
            setIsLoggedIn(true);
        }, 3000);
    };

    const handleHaveAccount = () =>{
        setIsRegisterPopup(!isRegisterPopup);
        toggleLoginPopup();
    }
    const handleHaveAccountFromLocation = () =>{
        toggleLocationPopup();
        toggleLoginPopup();
    }
    const handleHaveAccountFromVerifyOtp = () =>{
        toggleOtpPopup();
        toggleLoginPopup();
    }
    const handleCreateAccount = () =>{
        handleHaveAccount();
    }
    const toggleLocationPopup = () =>{
        setIsLocationPopup(!isLocationPopup)
    }
    const toggleOtpPopup = () =>{
        setIsOtpPopup(!isOtpPopup);
    }
    const toggleRegSuccessPopup =() =>{
        setRegSuccess(!regSuccess)
    }
    const toggleForgotPassPopUp =() =>{
        setForgotPass(!forgotPass)
    }
    const toggleResetSuccessPopUP = () =>{
        setResetSuccess(!resetSuccess);
    }
    const toggleShowLogPopUp = () =>{
        setShowLogPop(!showLogPop);
    }

    // Forgot password Section
    const handleForgotPasswordInputChange = (field, value) => {
        setForgotPasswordData((prevData) => ({
            ...prevData,
            [field]: value,
        }));
    };

    const openForgotPassordPopUp = () =>{
        toggleLoginPopup();
        toggleForgotPassPopUp();
    }

    const handleResetPassSuccess = () =>{
        
        toggleForgotPassPopUp();
        toggleResetSuccessPopUP();
        setTimeout(() => {
            setResetSuccess(false);
            toggleLoginPopup();
        }, 3000);
    }

    // Section for saving  the Registration form datas
    const handleRegisterInputChange = (field, value) => {
        setRegisterFormData((prevData) => ({
            ...prevData,
            [field]: value,
        }));
    };

    const handleCheckboxChange = (event) => {
        setRegisterFormData((prevData) => ({
            ...prevData,
            termsAccepted: event.target.checked,
        }));
    };

    const backToRegistration = ()=>{
        toggleLocationPopup();
        setIsRegisterPopup(!isRegisterPopup);
    }
    const backToLocation =()=>{
        toggleOtpPopup();
        setIsLocationPopup(!isLocationPopup);
    }


    const handleRegisterSubmit = async() => {
        const { createpass, confirmpass, email,mobile,firstname,country_code } = registerFormData;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        setErrorMessage("");

        if(!firstname.trim().length>0){
            setErrorMessage("Enter your first name to continue")
            return;
        }
        if(!country_code.trim().length>0){
            setErrorMessage("Select your country code to continue")
            return;
        }
        if (!mobile.trim().length>0){
            setErrorMessage("Enter your mobile number to continue")
            return;
        }
        if (mobile.trim().length!=9){
            setErrorMessage("Enter a valid 9 digit mobile number")
            return;
        }

        if(!email.trim().length>0){
            setErrorMessage("Enter your email to continue")
            return;
        }
        if (!emailRegex.test(email.trim())) {
            setErrorMessage("Invalid email format")
            return;
        }
        if (createpass !== confirmpass) {
            setErrorMessage("Passwords do not match. Please try again.")
            return;
        }
        else if(!createpass.trim().length>0 |  !confirmpass.trim().length>0){
            setErrorMessage("Enter your password to continue")
            return;
        }
        else if(!confirmpass.trim().length>0 |  !confirmpass.trim().length>0){
            setErrorMessage("Confirm your password to continue")
            return;
        }
        if (!registerFormData.termsAccepted) {
            setErrorMessage("You must agree to the Terms and Conditions to continue.");
            return;
        }
        const body = {
            "mobile":mobile,
            "email":email
        }

        const response = await UserExists({body})
        if(response.data.email_exists){
            setErrorMessage("Email already exists.Try another one.")
            return;
        }
        else if(response.data.mobile_exists){
            setErrorMessage("Mobile number already exists. Try another one.")
            return;
        }
        toggleRegisterPopUp();
        toggleLocationPopup();

    };

    const handleLocationSubmit = async() =>{
        setErrorMessage("");
        const { country,state,street1,building,zip,city } = registerFormData;
        const fields = {"country":country,"state":state,"street 1":street1,"building details":building,"city":city," ZIP code":zip}
        const message = LocationValidator({fields})
        if (message.length>0){
            setErrorMessage(message);
            return
        }
        const body={
            "mobile":registerFormData.mobile,
            "country_code":registerFormData.country_code
        }
        try{
            const response = await SendOTP({body})
            if (response.data.resp_code == 0){
                setErrorMessage(response.data.message);
                return;
            }
            else{
                if (response.data.resp_code === 1){
                    toggleOtpPopup();
                    toggleLocationPopup();
                }
                else{
                    console.error("error sending otp")
                }
            }
        }
        catch(error){
            console.log("error sending otp",error)
        }
        

    }

    const handleVerifyOtp = async() =>{
        const body = {
            "mobile":registerFormData.mobile,
            "otp":registerFormData.otp
        }

        try{
            const response = await VerifyOTP({body})
            if (response.error){
                setVerifyError("Otp doesn't match. Please try again.")
                return;
            }
            else{
                if (response.data.resp_code === 1){
                    setVerifyError("")
                    // Register the user
                    registerUser();
                }
                else{
                    setVerifyError("Otp doesn't match. Please try again.")
                }
            }
        }
        catch(error){
            console.log("error sending otp",error)
        }
    }

    const registerUser = async() =>{
        const body ={
            "first_name":registerFormData.firstname,
            "last_name":registerFormData.lastname,
            "mobile":registerFormData.mobile,
            "email":registerFormData.email,
            "password":registerFormData.confirmpass,
            "country":registerFormData.country,
            "country_code":registerFormData.country_code,
            "state":registerFormData.state,
            "street1":registerFormData.street1,
            "street2":registerFormData.street2,
            "building":registerFormData.building,
            "city":registerFormData.city,
            "zip":registerFormData.zip
        }
        try{
            const response = await RegisterUser({body})
            if (response.error){
                console.log("error registering the user")
                // setVerifyError("Otp doesn't match. Please try again.")
                return;
            }
            else{
                if (response.data.resp_code === 1){
                    toggleOtpPopup();
                    toggleRegSuccessPopup();
                    setTimeout(() => {
                        setRegSuccess(false);
                        toggleLoginPopup();

                    }, 2000);
                }
                else{
                    // setVerifyError("Otp doesn't match. Please try again.")
                    console.log("error")
                }
            }
        }
        catch(error){
            console.log("error sending otp",error)
        }
    }
    const onRegisterClick = () =>{
        toggleRegisterPopUp();
    }

    const routeToTabMapping = {
        "/": "home",
        "/help-support": "help-support",
    };

    useEffect(() => {
            const currentTab = routeToTabMapping[location.pathname] || "";
            setSelectedValue(currentTab);
        }, [location.pathname]);

    const [selectedValue, setSelectedValue] = useState("home");
    const navigate = useNavigate();
    const handleSelection = (value,route) => {
        setSelectedValue(value);
        navigate(route);
    };

    const handleLoginClick =() =>{
        toggleLoginPopup();
        setShowLogPop(false);

    }
    const handleRegisterClick =() =>{
        toggleRegisterPopUp();
        setShowLogPop(false);

    }
    const [harmburgerState,setHarmburgerState] = useState(false);
    const [screenSize, setScreenSize] = useState(window.innerWidth);

    // To toggle the navbar items in small screens
    const handleHarburgerClick = ()=>{
        setHarmburgerState(!harmburgerState)
    }

    useEffect(() => {
        const handleResize = () => {
            setScreenSize(window.innerWidth);
        }
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    });


    return (
        <div className="nav-container same-v-line">
            <div className="nav-inner-container">
                <div className="nav-logo-img">
                    <img src={navlogo} alt="Navbar logo" onClick={() => navigate("/")}/>
                </div>
                {(harmburgerState || screenSize > 992) && (
                    <div className="nav-right-section">
                            <div className="tab-section">

                                <TabSwitch
                                key={"home"}
                                content={"Home"}
                                name={"sub-nav-bar-tabs"}
                                id={"home"}
                                htmlFor={"home"}
                                selectedValue={selectedValue}
                                onSelection={() => handleSelection("home","/")} 
                                />

                                <TabSwitch
                                key={"help-support"}
                                content={"Help and Support"}
                                name={"sub-nav-bar-tabs"}
                                id={"help-support"}
                                htmlFor={"help-support"}
                                selectedValue={selectedValue}
                                onSelection={() => handleSelection("help-support","/help-support")} 
                                />

                            <LanguageSwitch2/>
                        </div>
                        <div className="login-reg-button-box">
                            <PrimaryStrokeButton label="Log In" onClick={manageLoginPopUp} />
                            <PrimarySolidButton label="Create Account" onClick={onRegisterClick}/>
                        </div>
                </div>)}
                
            </div>
            <i class="harmburger-icon fa-solid fa-bars" onClick={handleHarburgerClick}></i>
            {isLoginPopupOpen && <Login onClose={toggleLoginPopup} 
                    onLoginSuccess={handleLoginSuccess} 
                    onCreateAccnt={handleCreateAccount} 
                    onForgotPass={openForgotPassordPopUp}
                />}
                 {/* onClose={toggleLoginPopup} onLoginSuccess={handleLoginSuccess} onCreateAccnt={handleCreateAccount} onForgotPass={openForgotPassordPopUp} */}
            
            {isRegisterPopup &&  <CreateAccount 
                onClose={toggleRegisterPopUp} onLoginNow={handleHaveAccount}
                onInputChange={handleRegisterInputChange} onSubmit={handleRegisterSubmit}
                errorMessage = {errorMessage} checkboxChecked={registerFormData.termsAccepted}
                onCheckboxChange={handleCheckboxChange}
                formData={registerFormData}
                setFormData={setRegisterFormData}

            /> } 
            {isLocationPopup && 
                <LocationForm2
                    onClose={toggleLocationPopup} 
                    onInputChange={handleRegisterInputChange} 
                    onSubmit={handleLocationSubmit} 
                    onLoginNow={handleHaveAccountFromLocation}
                    errorMessage = {errorMessage}
                    onBackButtonClick = {backToRegistration}
                    formData={registerFormData}
                />
            }
            {isOtpPopup && 
                <OtpForm 
                    error={verifyOptError} 
                    onSubmit={handleVerifyOtp} 
                    onInputChange={handleRegisterInputChange} 
                    onLoginNow={handleHaveAccountFromVerifyOtp}
                    onClose={toggleOtpPopup}
                    onBackButtonClick = {backToLocation}
                />
            }
            
            {regSuccess && <ScuccessPopUp message={"Account created successfully"}/>}
            {resetSuccess && <ScuccessPopUp message={"Account reset successfully"}/>}
            {forgotPass && 
                <ForgotPassword 
                    onClose={toggleForgotPassPopUp}
                    onInputChange={handleForgotPasswordInputChange}
                    onComplete = {handleResetPassSuccess}
                />
            }
            {showLogPop && <ShowLogPopUp 
                onClose={toggleShowLogPopUp}
                onLoginClick={handleLoginClick}
                onRegisterClick={handleRegisterClick}
            />}
                                
            
        </div>
        
    )
}


export default NavBar