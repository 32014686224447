import React from "react";
import { useContext, useEffect,useState } from "react";
import './LocationForm.css';
import moment from 'moment';

// components
import PrimarySolidButton from "../../Buttons/PrimarySolidButton/PrimarySolidButton";
import PopUpMainHeading from "../../Heading/PopUpMainHeading/PopUpMainHeading";
import AccountWarning from "../TextItems/AccountWarning/AccountWarning";
import PopUpSubHeading from "../../Heading/PopUpSubHeading/PopUpSubHeading";
import InputTextField from "../../InputFields/InputTextField/InputTextField";
import ErrorMessage from "../../ErrorMessage/ErrorMessage";

const LocationForm = ({data,isRegForm=false,onClose,onSave,errorMessage=""}) =>{
    const [formData, setFormData] = useState(data);
    const formattedDate = moment(data.visit_date).format("DD-MMM-YYYY");
    const formattedTime = moment(data.visit_time, "HH:mm:ss").format("hh A");
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };
    const handleSave = () => {
        onSave(formData); // Pass updated data to the parent
    };
    const handleOverlayClick = (e) =>{
        if(e.target.className === 'login-popup-overlay'){
            onClose();
        };
    }

    return (
        <div className="login-popup-overlay" onClick={handleOverlayClick}>
            <div className="register-pop-up">
                <div className="register-head-section">
                    <PopUpMainHeading content={"Enter Location"}/>
                    <PopUpSubHeading content={"Enter your location details so we can reach you easily!"}/>
                </div>
                <form className="login-input-button-section">
                    {errorMessage && <ErrorMessage message={errorMessage}/>}
                    <div className="input-field-section">
                        <InputTextField htmlFor={"country"} onChange={handleInputChange} name={"country"}  content={'Country'} value={formData.country} required={true}/>
                        <InputTextField htmlFor={"state"} onChange={handleInputChange} name={"state"} content={'State/Province/Region'} value={formData.state} required={true}/>
                        <div className="half-width-input gap-62">
                            <InputTextField htmlFor={"street1"} onChange={handleInputChange} name={"street1"} content={'Street 1'} value={formData.street1} required={true}/>
                            <InputTextField htmlFor={"street2"} onChange={handleInputChange} name={"street2"} content={'Street 2'} value={formData.street2}/>
                        </div>
                        <InputTextField htmlFor={"housenumber"} onChange={handleInputChange} name={"building"} content={'House/Building Number/Apartment'} value={formData.building} required={true}/>
                        <div className="half-width-input gap-62">
                            <InputTextField htmlFor={"city"} onChange={handleInputChange} name={"city"} content={'City'} value={formData.city} required={true}/>
                            <InputTextField htmlFor={"zipcode"} onChange={handleInputChange} name={"zip"} content={'Zipcode'} value={formData.zip} required={true}/>
                        </div>
                    </div>
                    
                    <div className={`forgot-already-account-box margin-new ${isRegForm ? "" : "not-reg-form"}`}>
                        {isRegForm && <AccountWarning content={"Already have an account?"}
                        link={"Log in now"}/>}
                        
                        <PrimarySolidButton label={"Save Changes"} onClick={handleSave} />  
                    </div>
                </form>
            </div>
        </div>
    )
}
export default LocationForm;