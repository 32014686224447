import React,{useContext} from "react";
import './CustomerSupport.css';

import { AuthContext } from "../../services/AuthContext";

// components
import NavBar from "../../components/NavBar/NavBar";
import NavBarMain from "../../components/NavBarMain/NavBarMain";
import Footer from "../../components/Footer/Footer";
import ContactCard from "../../components/ContactCard/ContactCard";
// images
import phone from '../../assets/phone.svg';
import flag from '../../assets/flag.png';
import mail from '../../assets/mail.svg';

const CustomerSupport = ()=>{
    const {isAuthenticated} = useContext(AuthContext);

    return(
        <div className="body-container">
            <div>
                {isAuthenticated?<NavBarMain/>:
                <NavBar/> }
                <div className="page-title-box">
                    <div className="page-title">
                        Contact Us
                    </div>
                </div>
                <div className="support-description">
                    <span>
                    Need help? We're here to assist you! If you have any questions, 
                    concerns, or need support with your requests, our team is always ready to help. We strive to 
                    provide a smooth and hassle-free experience, ensuring all your needs are met efficiently. 
                    Feel free to reach out to us anytime for assistance.
                    </span>
                </div>
                <div className="contact-card-wrapper">
                    <div className="flag-address-box">
                        <div className="flag-image">
                            <img src={flag} alt="" />
                        </div>
                        <div className="office-address">
                            <span>Al Faisalyah, Jeddah, KSA</span>
                        </div>
                    </div>
                    <ContactCard 
                        image={phone} 
                        heading={"General Enquiry"} 
                        content={["+9662797823987","+966506642273"]}  
                    />
                    <ContactCard 
                        image={mail} 
                        heading={"Support Mail"} 
                        content={["info@ultra-waves.com"]}  
                    />

                </div>

            </div>
            <Footer/>
        </div>
    )
}
export default CustomerSupport;