import React, { useEffect, useState } from "react";
import './CreateAccount.css';
import '../../../Common.css'

// components
import PrimarySolidButton from "../../Buttons/PrimarySolidButton/PrimarySolidButton";
import PopUpMainHeading from "../../Heading/PopUpMainHeading/PopUpMainHeading";
import AccountWarning from "../TextItems/AccountWarning/AccountWarning";
import PopUpSubHeading from "../../Heading/PopUpSubHeading/PopUpSubHeading";
import InputTextField from "../../InputFields/InputTextField/InputTextField";
import PasswordInput from "../../InputFields/InputPasswordField/InputPasswordField";
import ErrorMessage from "../../ErrorMessage/ErrorMessage";
import CheckBox from "../../InputFields/CheckBoxInput/CheckBox";
import SelectField from "../../InputFields/SelectField/SelectField";



const CreateAccount = ({onClose,onLoginNow,onInputChange, onSubmit,errorMessage,checkboxChecked, onCheckboxChange,formData,setFormData}) =>{
    const [selected,setSelectedValue] = useState(formData.country_code || "");
    const handleOverlayClick = (e) =>{
        if(e.target.className === 'login-popup-overlay'){
            onClose();
        };
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        onInputChange(name, value); // Pass input data to the parent component
    };

    const handleSelectionChange = (event) => {
        setSelectedValue(event.target.value);
        setFormData((prevData) => ({
            ...prevData,
            country_code: event.target.value, // Update 'country' field
        }));
    };

    const options = [
        { value: "966", label: "+966 (Saudi)" }, 
        { value: "962", label: "+962 (Jordan)" }
    ];

    return (
        <div className="login-popup-overlay" onClick={handleOverlayClick}>
            <div className="register-pop-up">
                <div className="register-head-section">
                    <PopUpMainHeading content={"Create your account"}/>
                    <PopUpSubHeading content={"Get started effortlessly in just a few simple steps!"}/>
                </div>
                
                <form className="login-input-button-section">
                    {errorMessage && <ErrorMessage message={errorMessage}/>}
                    <div className="input-field-section">
                        <div className="half-width-input gap-62">
                            <InputTextField htmlFor={"firstname"} content={'First Name'} name={"firstname"}
                                onChange={handleInputChange} value={formData.firstname} required={true}/>
                            <InputTextField htmlFor={"lastname"} content={'Last Name'} name={"lastname"}
                                onChange={handleInputChange} value={formData.lastname}/>
                        </div>
                        <div className="mobile-with-code gap-62">
                            <SelectField htmlFor={"coutrycode"} content={'Country Code'} 
                                required={true} options={options}
                                onChange={handleSelectionChange}
                                dvalue={selected}
                                name={"contry_code"}

                            />
                            <InputTextField 
                                htmlFor={"mobile"} content={'Mobile'} 
                                name={"mobile"} onChange={handleInputChange} 
                                type="mobile" value={formData.mobile} required={true}
                            />
                        </div>
                        
                        
                        <InputTextField htmlFor={"email"} content={'Email'} name={"email"}
                            onChange={handleInputChange} value={formData.email} required={true}/>
                        
                        <div className="half-width-input gap-62">
                            <PasswordInput htmlFor={"createpass"} content={'Create Password'} name={"createpass"}
                                
                                onChange={handleInputChange} hasPhoneIcon={false} value={formData.createpass} required={true}/>
                            <PasswordInput htmlFor={"confirmpass"} content={'Confirm Password'} name={"confirmpass"}
                                
                                onChange={handleInputChange} hasPhoneIcon={false} value={formData.confirmpass} required={true}/>
                        </div>
                    </div>
                    <div className="terms-conditions-box">
                        <CheckBox label={"Terms and Conditions"} checked={checkboxChecked} onChange={onCheckboxChange}/>
                    </div>
                    <div className="forgot-already-account-box margin-new">
                        <AccountWarning content={"Already have an account?"}
                        link={"Log in now"} onClick={onLoginNow}/>
                        <PrimarySolidButton label={"Continue"} onClick={onSubmit}/>
                    </div>
                </form>
            </div>
        </div>
    )
}
export default CreateAccount;
