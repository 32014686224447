import React,{useState,useEffect,useContext} from "react";
import { useLocation } from "react-router-dom";
import { AuthContext } from "../../services/AuthContext";
// components
import NavBarAR from "../../components/NavBar/NavBarAR";
import NavBarMainAR from "../../components/NavBarMain/NavBarMainAR";
import FooterAR from "../../components/Footer/FooterAR";
import CategoryAndSrviceTabAR from "../../components/CategoryAndServiceTab/CategoryAndServiceTabAR";
import SecondarySearchBoxAR from "../../components/Search/SecondarySearch/SecondarySearchAR";
import PaginationAR from "../../components/Pagination/PaginationAR";
// css
import '../../Common.css';
// import './services.css'

const ServicesAR = ()=>{
    const location = useLocation();
    const [activeTab, setActiveTab] = useState(location.state?.tab||"categories"); 
    const [searchValue, setSearchValue] = useState(""); 
    const {isAuthenticated} = useContext(AuthContext)
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    useEffect(() => {
        const tab = location.state?.tab;
        if (tab) {
            setActiveTab(tab); 
        }
    }, [location.state]);

    useEffect(()=>{
            setCurrentPage(1);
        },[activeTab])

    const getPlaceholder = () => {
        return activeTab === "categories"
            ? "فئة البحث"
            : "خدمة البحث";
    };

    const handleSearchSubmit = (value) => {
        setSearchValue(value); // Update search value
    };
    return(
        <div className="body-container">
            <div>
            {isAuthenticated?<NavBarMainAR/>:
                <NavBarAR/> 
            }
                <div className="page-title-box">
                    <div className="page-title">
                        خدماتنا 
                    </div>
                    <div className="page-text-content">
                        اكتشف مجموعة من الخدمات المريحة المصممة لتلبية احتياجاتك وجعل الحياة اليومية أكثر بساطة
                    </div>
                </div>
                <div className="service-search-container">
                    <div className="service-seach-box">
                        <SecondarySearchBoxAR
                            placeholder={getPlaceholder()}
                            onSubmit={handleSearchSubmit}
                        />
                    </div>
                </div>
                <div className="category-service-container">
                    <CategoryAndSrviceTabAR
                        activeClassName = 'selected'
                        tab={activeTab}
                        search={searchValue}
                        onTabChange={setActiveTab}
                        currentPage = {currentPage}
                        setTotalPages = {setTotalPages}
                    />
                </div>
                <PaginationAR
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={setCurrentPage}
                />
            </div>
            <FooterAR/>
        </div>
    )
};

export default ServicesAR;