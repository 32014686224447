import React from "react";
import './DeleteAccount.css';
// components
import PopUpMainHeading from "../../Heading/PopUpMainHeading/PopUpMainHeading";
import PrimaryMutedButton from "../../Buttons/PrimaryMutedButton/PrimaryMutedButton";
import PrimarySolidButton from "../../Buttons/PrimarySolidButton/PrimarySolidButton";
import PopUpSubHeading from "../../Heading/PopUpSubHeading/PopUpSubHeading";


const DeleteAccount =({onClose,onDelete,onCancel})=>{

    const handleOverlayClick = (e) =>{
        if(e.target.className === 'login-popup-overlay'){
            onClose();
        };
    }
    return(
        <div className="login-popup-overlay" onClick={handleOverlayClick}>
            <div className="register-pop-up">
                <div className="closeButton" onClick={onClose}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                    <path d="M21 7L7 21" stroke="#666666" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M7 7L21 21" stroke="#666666" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
                <div className="register-head-section">
                    <PopUpMainHeading content={"Deactivate Your Account"}/>
                    <PopUpSubHeading content={"Are you sure you want to deactivate your account? This action may affect your access."}/>
                </div>
                <form className="login-input-button-section">
                    <div className="forgot-already-account-box margin-new new-box-style delete-account-buttons">
                        <PrimaryMutedButton onClick={onCancel} label={"Cancel"}/>
                        <PrimarySolidButton label={"Deactivate"} onClick={onDelete} bcolor={"bg-red"}/>
                    </div>
                </form>
            </div>
        </div>
    )
}


export default DeleteAccount;