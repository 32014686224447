
const LocationValidator = ({ fields }) => {
    let error = ""
    for (const key in fields) {
        if (fields[key].trim().length === 0) {
            error = `Enter your ${key} to continue.`
            return error;
        }
        else {
            error = ""
        }
    }
    return error
};


const LocationValidatorAR = ({ fields }) => {
    const map = {"country":"دولة","state":"الدولة","street1":"شارع 1",
        "building":"رقم المبنى","city":"مدينة","zip":"الرمز البريدي"
    }
    let error = ""
    for (const key in fields) {
        if (fields[key].trim().length === 0) {
            error = `لا يمكن أن تكون فارغة ${map[key]}`
            return error;
        }
        else {
            error = ""
        }
    }
    return error
};

const getNextTime = ()=>{
    let now = new Date();
    let hours = now.getHours();
    hours  = parseInt(hours+1,10);
    hours = hours>18?"18":hours.toString()
    return hours;
}

const isToday =(selectedDate) =>{
    
    const selectedDateObj = new Date(selectedDate);
    const today = new Date();
    const yesToday = selectedDateObj.getFullYear() === today.getFullYear() &&
                    selectedDateObj.getMonth() === today.getMonth() &&
                    selectedDateObj.getDate() === today.getDate();
    return yesToday
}

const beforeToday = (selectedDate)=>{
    const selectedDateObj = new Date(selectedDate);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    selectedDateObj.setHours(0, 0, 0, 0);
    return selectedDateObj < today;
}
    

export  {LocationValidator,LocationValidatorAR,getNextTime,isToday, beforeToday};