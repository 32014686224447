import React from "react";
import '../../../Common.css';
import '../Buttons.css';
import './PrimarySolidButton.css';

const PrimarySolidButton = ({label,onClick,disabled=false,type="button",bcolor })=>{
    return (
        <button className={`btn primary-solid-btn ${disabled ? "disabled" : ""} ${bcolor ? bcolor : ""}`} 
            onClick={!disabled?onClick:null} type={type}>{label} 
        </button>
    )
}


export default PrimarySolidButton;