import React from 'react';
import './Pagination.css'; // Add CSS for styling

const PaginationAR = ({ currentPage, totalPages, onPageChange }) => {
const handlePrevious = () => {
    if (currentPage > 1) {
        onPageChange(currentPage - 1);
    }
};

const handleNext = () => {
    if (currentPage < totalPages) {
        onPageChange(currentPage + 1);
    }
};

const renderPageNumbers = () => {
    const pageNumbers = [];

    for (let i = 1; i <= totalPages; i++) {
        if (i === 1 || i === totalPages || (i >= currentPage - 2 && i <= currentPage + 2)) {
        pageNumbers.push(
            <button
                key={i}
                onClick={() => onPageChange(i)}
                className={`page-number ${i === currentPage ? 'active' : ''}`}
            >
            {i}
            </button>
        );
        } else if (
            pageNumbers[pageNumbers.length - 1]?.props.children !== '...'
        ) {
            pageNumbers.push(
            <span key={`ellipsis-${i}`} className="ellipsis">
                ...
            </span>
            );
        }
    }

    return pageNumbers;
};

return (
    <div className="pagination-main-container">
        <div className="pagination arabic-flex">
                <button
                    className='previous-button arabic-flex'
                    onClick={handlePrevious}
                    disabled={currentPage === 1}
                    >
                    <svg className="rotate-180" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M15.8332 9.99996H4.1665M4.1665 9.99996L9.99984 15.8333M4.1665 9.99996L9.99984 4.16663" stroke="#666666" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    سابق
                </button>
                
            
            <div className="page-numbers-buttons arabic-flex">
                {renderPageNumbers()}
            </div>
            <button
                onClick={handleNext}
                className="next-button arabic-flex"
                disabled={currentPage === totalPages}
                >
                التالي 
                <svg className='rotate-180' xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M4.16699 9.99996H15.8337M15.8337 9.99996L10.0003 4.16663M15.8337 9.99996L10.0003 15.8333" stroke="#666666" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                
            </button>
        </div>
    </div>
    );
};

export default PaginationAR;