import React from "react";
import '../../Common.css';
import './Footer.css';
import logo from '../../assets/logo.png';
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../../services/BaseUrl";


const FooterAR = ()=>{

    const navigate = useNavigate();
    const handleOpen = (url) => {
        window.open(url, "_blank"); // Opens in a new tab
    };

    return (
        <div className="footer-container same-v-line">
            <div className="footer-inner-container footer-inner-container-ar">
                <div className="contact-container contact-container-ar">
                    <div className="footer-logo-box">
                        <img src={logo} alt="Fraternity Logo" />
                    </div>
                    <div className="contact contact-ar">
                        <div>
                            <div className="heading">اتصل بنا</div>
                            <div className="address">1234 شارع إلم، جناح 567 سبرينغفيلد، نيويورك 12345 الولايات المتحدة</div>
                        </div>
                        <div>
                            <div>+1 (555) 123-4567 : هاتف </div>
                            <div>support@example.com : بريد إلكتروني </div>
                        </div>
                    </div>
                </div>
                <div className="terms-privacy-box">
                    <span onClick={() => handleOpen(`${baseUrl}/terms-conditions/`)}>الشروط والأحكام</span>
                    <span onClick={() => handleOpen(`${baseUrl}/privacy-policy/`)}>سياسة الخصوصية</span>
                </div>
            </div>
        </div>
    )
}

export default FooterAR;