import React from "react";
import './ContactCard.css';
import { defaultFormat } from "moment";


const ContactCard =({image,heading,content}) =>{
    return(
        <div className="contact-card-main">
            <div className="icon-img-box">
                <img src={image} alt="" />
            </div>
            <div className="text-section-contact-card">
                <span>{heading}</span>
                {content.map((item,index)=>(
                    <span key={index}>{item}</span>
                ))}
            </div>
        </div>
    )
}

export default ContactCard;
