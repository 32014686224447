import React from "react";
import '../../Common.css';
import './Footer.css';
import logo from '../../assets/logo.png';
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../../services/BaseUrl";


const Footer = ()=>{

    const navigate = useNavigate();
    const handleOpen = (url) => {
        window.open(url, "_blank"); // Opens in a new tab
    };

    return (
        <div className="footer-container same-v-line">
            <div className="footer-inner-container">
                <div className="contact-container">
                    <div className="footer-logo-box">
                        <img src={logo} alt="Fraternity Logo" />
                    </div>
                    <div className="contact">
                        <div>
                            <div className="heading">Contact Us</div>
                            <div className="address">Al Faisalyah, Jeddah, KSA</div>
                            <div >Website: www.ultra-waves.com</div>
                        </div>
                        <div>
                            <div>Phone: 00966536050504</div>
                            <div>Email: info@khadamtiplus.com </div>
                        </div>
                    </div>
                </div>
                <div className="terms-privacy-box">
                    <span onClick={() => handleOpen(`${baseUrl}/terms-conditions/`)}>Terms and Conditions</span>
                    <span onClick={() => handleOpen(`${baseUrl}/privacy-policy/`)}>Privacy Policy</span>
                </div>
            </div>
        </div>
    )
}

export default Footer;