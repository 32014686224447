import React from "react";
import '../../../Common.css'
import '../CommonSearch.css'
import './PrimarySearch.css'

const PrimarySearchBox = ({onChange,name,htmlFor,value,onSearch,btype='submit'})=>{
    const handleFormSubmit = (e) => {
        e.preventDefault();
        onSearch(); // Pass the input value to the parent
    };
    return(
            <form className="search-container" onSubmit={handleFormSubmit}>
                <div className="icon-search-container">
                    <div className="search-svg-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
                            <path d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M21 21L16.7 16.7" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                    <input type="text" htmlFor={htmlFor} value={value} name={name} placeholder="Search Service" onChange={onChange} className="search-input" />
                </div>
                <div className="search-button-container">
                    <button type={btype} className="search-button" >
                        Search
                    </button>
                </div>
            </form>
    )
}

export default PrimarySearchBox;