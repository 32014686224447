import React,{useState} from "react";
import './TimeCard.css'
import { getNextTime,isToday,beforeToday } from "../../../services/Utilities";
// components
import RadioTileInput from "../../InputFields/RadioInputs/RadioTileInput/RadioTileInput";

const TimeCard = ({onSelection,currentSelection="9",selectedDate}) =>{
    const [selectedValue, setSelectedValue] = useState(currentSelection);
    const handleSelection = (value) => {
        setSelectedValue(value);
        if (onSelection) {
            onSelection(value); // Pass the selected value to the parent
        }
    };
    return(
        <div className="card-container">
            <div className="card-wrapper">
                {["9", "10", "11","12","13","14","15",
                    "16","17","18"
                ].map((time) => {
                    const id = time.trim();
                    // TimeButton is muted if selected date is before today -
                    // or selected date is today and the time is below the current time. 
                    const muted = beforeToday(selectedDate)?true:isToday(selectedDate)?parseInt(id,10)<parseInt(getNextTime(),10):false;
                    const timeNumber = parseInt(time.trim(), 10);
                    const period = id < 12 ? "AM" : "PM";
                    const formattedTime = timeNumber > 12 ? timeNumber - 12 : timeNumber;
                    const displayTime = `${formattedTime} ${period}`;
                    return (
                        <RadioTileInput
                            key={id}
                            content={displayTime}
                            name={"service-time"}
                            id={id}
                            htmlFor={id}
                            selectedValue={selectedValue}
                            onSelection={handleSelection}
                            muted={muted}
                        />
                    );
                })}
            </div>
        </div>
    )
}

export default TimeCard;