import React,{useState} from "react";
import './CreateAccount.css';
import '../../../Common.css'

// components
import PrimarySolidButton from "../../Buttons/PrimarySolidButton/PrimarySolidButton";
import PopUpMainHeading from "../../Heading/PopUpMainHeading/PopUpMainHeading";
import AccountWarningAR from "../TextItems/AccountWarning/AccountWarningAR";
import PopUpSubHeading from "../../Heading/PopUpSubHeading/PopUpSubHeading";
import InputTextField from "../../InputFields/InputTextField/InputTextField";
import PasswordInput from "../../InputFields/InputPasswordField/InputPasswordField";
import ErrorMessageAR from "../../ErrorMessage/ErrorMessageAR";
import CheckBoxAR from "../../InputFields/CheckBoxInput/CheckBoxAR";
import SelectField from "../../InputFields/SelectField/SelectField";



const CreateAccountAR = ({onClose,onLoginNow,onInputChange, onSubmit,errorMessage,checkboxChecked, onCheckboxChange,formData,setFormData}) =>{
    const [selected,setSelectedValue] = useState(formData.country_code || "");
    const handleOverlayClick = (e) =>{
        if(e.target.className === 'login-popup-overlay'){
            onClose();
        };
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        onInputChange(name, value); // Pass input data to the parent component
    };

    const handleSelectionChange = (event) => {
        setSelectedValue(event.target.value);
        setFormData((prevData) => ({
            ...prevData,
            country_code: event.target.value, // Update 'country' field
        }));
    };

    const options = [
        { value: "966", label: "+966 (سعودي)" }, 
        { value: "962", label: "+962 (الأردن)" }
    ];

    return (
        <div className="login-popup-overlay" onClick={handleOverlayClick}>
            <div className="register-pop-up">
                <div className="register-head-section">
                    <PopUpMainHeading content={"أنشئ حسابكt"}/>
                    <PopUpSubHeading content={"ابدأ دون عناء في بضع خطوات بسيطة!"}/>
                </div>
                <form className="login-input-button-section">
                    {errorMessage && <ErrorMessageAR message={errorMessage}/>}
                    <div className="input-field-section">
                        <div className="half-width-input gap-62 arabic-input">
                            <InputTextField htmlFor={"firstname"} content={'الاسم الأول'} name={"firstname"}
                                onChange={handleInputChange} isArabic={true} dir="rtl" value={formData.firstname} required={true}/>
                            <InputTextField htmlFor={"lastname"} content={'اسم العائلة'} name={"lastname"}
                                onChange={handleInputChange} isArabic={true} dir="rtl" value={formData.lastname}/>
                        </div>
                        <div className="mobile-with-code gap-62 arabic-flex">
                            <SelectField htmlFor={"coutrycode"} content={'رمز البلد'} 
                                required={true} options={options}
                                onChange={handleSelectionChange}
                                dvalue={selected}
                                isArabic={true}

                            />

                            <InputTextField htmlFor={"mobile"} 
                                content={'متحرك'} name={"mobile"}
                                onChange={handleInputChange} isArabic={true} dir="rtl" 
                                type="mobile" value={formData.mobile} required={true}
                            />
                        </div>
                        
                        <InputTextField htmlFor={"email"} content={'بريد إلكتروني'} name={"email"}
                            onChange={handleInputChange} isArabic={true} dir="rtl" value={formData.email} required={true}/>
                        <div className="half-width-input gap-62 arabic-input">
                            <PasswordInput htmlFor={"createpass"} content={'قم بإنشاء كلمة مرور'} name={"createpass"}
                                type="password"
                                onChange={handleInputChange}
                                isArabic={true} dir="rtl"
                                hasPhoneIcon={false}
                                value={formData.createpass}
                                required={true}/>
                            <PasswordInput htmlFor={"confirmpass"} content={'تأكيد كلمة المرور'} name={"confirmpass"}
                                type="password"
                                onChange={handleInputChange}
                                isArabic={true} dir="rtl"
                                hasPhoneIcon={false}
                                value={formData.confirmpass}
                                required={true}/>
                        </div>
                    </div>

                    <div className="terms-conditions-box">
                        <CheckBoxAR label={"الشروط والأحكام"} checked={checkboxChecked} onChange={onCheckboxChange}/>
                    </div>

                    <div className="forgot-already-account-box margin-new arabic-flex">
                        <AccountWarningAR content={"هل لديك حساب بالفعل؟"}
                        link={"قم بتسجيل الدخول الآن"} onClick={onLoginNow}/>
                        <PrimarySolidButton label={"يكمل"} onClick={onSubmit}/>
                    </div>
                </form>
            </div>
        </div>
    )
}
export default CreateAccountAR;
