import React,{useState} from "react";
import './CreateAccount.css';
import '../../../Common.css';
import { SendOTP,ResetPassword } from "../../../services/Api";

// components
import PrimarySolidButton from "../../Buttons/PrimarySolidButton/PrimarySolidButton";
import PopUpMainHeading from "../../Heading/PopUpMainHeading/PopUpMainHeading";
import AccountWarning from "../TextItems/AccountWarning/AccountWarning";
import PopUpSubHeading from "../../Heading/PopUpSubHeading/PopUpSubHeading";
import InputTextField from "../../InputFields/InputTextField/InputTextField";
import ScuccessPopUp from "../AccountCreationSuccess/ScuccessPopUp";
import PasswordInput from "../../InputFields/InputPasswordField/InputPasswordField";
import ErrorMessage from "../../ErrorMessage/ErrorMessage";




const ResetPasswordPopUp = ({ onClose,onSubmit,onComplete,mobile }) => {
    const [otpError, setOtpError] = useState(""); // To show OTP errors if any
    const [formData, setFormData] = useState({
        mobile: mobile,
        otp: "",
        newPassword: "",
        confirmPassword: "",
    });

    const handleOverlayClick = (e) => {
        if (e.target.className === "login-popup-overlay") {
            onClose();
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    

    const handleResetAccount = async () => {
        const { newPassword, confirmPassword } = formData;

        if (!formData.otp.trim()) {
            setOtpError("Please enter the OTP.");
        return;
        }
        if(!newPassword.trim().length>0 ){
            setOtpError("Please enter the new password.")
            return;
        }
        if(!confirmPassword.trim().length>0){
            setOtpError("Please confirm your new password.")
            return;
        }
        if (newPassword !== confirmPassword) {
            setOtpError("Passwords don’t match. Please try again.")
            return;
        }
        const body = {
            "mobile":formData.mobile,
            "otp":formData.otp,
            "password":formData.newPassword,
            "confirm_password":formData.confirmPassword
        }

        try {
            const response = await ResetPassword({ body });
            if (response.error){
                setOtpError("Otp doesn't match. Please try again.")
                return;
            }
            else{
                if (response.data.resp_code === 1){
                    setOtpError("")
                    onComplete();
                }
                else{
                    setOtpError("Otp doesn't match. Please try again.")
                }
            }
            
        } catch (error) {
            console.error("Error verifying OTP:", error);
            setOtpError("Something went wrong. Please try again.");
        }
    };


    return (
    <div className="login-popup-overlay" onClick={handleOverlayClick}>
        <div className="register-pop-up">
            <div className="register-head-section">
            <PopUpMainHeading content={"Reset Your Account Password"} />
            <PopUpSubHeading
                content={"We've sent a verification code to your mobile number. Please enter it below."}
            />
        </div>
        <form className="login-input-button-section">
            {otpError && <ErrorMessage message={otpError}/>}
            {
            <div className="input-field-section">
                <InputTextField
                    htmlFor={"otp"}
                    content={"Enter OTP"}
                    name={"otp"}
                    value={formData.otp}
                    onChange={handleChange}
                />
                <PasswordInput
                    htmlFor={"newPassword"}
                    content={"New Password"}
                    name={"newPassword"}
                    value={formData.newPassword}
                    onChange={handleChange}
                    type="password"
                    hasPhoneIcon={false}
                />
                <PasswordInput
                    htmlFor={"confirmPassword"}
                    content={"Confirm Password"}
                    name={"confirmPassword"}
                    value={formData.confirmPassword}
                    onChange={handleChange}
                    type="password"
                    hasPhoneIcon={false}
                />
                <div className="button-padding"></div>
                <PrimarySolidButton label={"Change Password"} onClick={handleResetAccount} />
            </div>
            }
        </form>
        </div>
    </div>
    );
};

export default ResetPasswordPopUp;
