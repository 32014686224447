import React from "react";
import '../../Common.css';
import './MyProfilePage.css';
// api and other functions
import { useContext,useEffect,useState } from "react";
import { AuthContext,logout } from "../../services/AuthContext";
import { PersonalProfileAPI,UpdateProfileAPI,DeleteAccountAPI } from "../../services/Api";
import { useNavigate } from "react-router-dom";
import { SendOTP,VerifyOTP,UserExists } from "../../services/Api";
import { LocationValidator } from "../../services/Utilities";
// Components
import NavBar from "../../components/NavBar/NavBar";
import NavBarMain from "../../components/NavBarMain/NavBarMain";
import Footer from "../../components/Footer/Footer";
import InputTextField from "../../components/InputFields/InputTextField/InputTextField";
import PrimaryMutedButton from "../../components/Buttons/PrimaryMutedButton/PrimaryMutedButton";
import PrimarySolidButton from "../../components/Buttons/PrimarySolidButton/PrimarySolidButton";
import UserIconAndName from "../../components/UserIconAndName/UserIconAndName";
import ResetPasswordPopUp from "../../components/PopUps/ForgotPassword/ResetPassword";
import DeleteAccount from "../../components/PopUps/DeleteAccountPopUP/DeleteAccount";
import ScuccessPopUp from "../../components/PopUps/AccountCreationSuccess/ScuccessPopUp";
import ValidateMobilePopUp from "../../components/PopUps/ForgotPassword/ValidateMobilePopUp";
import ErrorMessage from "../../components/ErrorMessage/ErrorMessage";
import { containerClasses } from "@mui/material";


const MyProfilePage = () => {
    const { fetchWithAuth,logout } = useContext(AuthContext);
    const navigate = useNavigate();
    const [ischangePass,setIsChangePass] = useState(false);
    const [isEditable, setIsEditable] = useState(false);
    const [isDeleteAccount, setIsDeleteAccount] = useState(false);
    const [isDeleteSuccess,setIsDeleteSuccess] =  useState(false);
    const [errorMessage,setErrorMessage] = useState("");
    const [locationErrorMessage,setLocationErrorMessage] = useState("");
    const [navKey, setNavKey] = useState(0);
    const [showOtpPopUp,setShowOtpPopUp] = useState(false);
    const [isRequired,setIsRequired] = useState(false);
    const [profileData, setProfileData] = useState({
        firstName: '',
        lastName: '',
        mobile: '',
        email: '',
        country: '',
        street1: '',
        street2: '',
        buildingDetails: '',
        city: '',
        state: '',
        zipcode: '',
        otp:''
    });
    const [initialMobile, setInitialMobile] = useState(profileData.mobile);
    const [initialEmail,setInitialEmail] = useState(profileData.email);
    const [otpError, setOtpError] = useState("");

    // useEffect(() => {
    //     setInitialMobile(profileData.mobile);
    // }, []);

    useEffect(() => {
        const fetchProfileData = async () => {
            try {
                const response = await fetchWithAuth(PersonalProfileAPI);
                if (response.error) {
                    console.error("Error fetching profile:", response.error);
                } else {
                    setProfileData({
                        firstName: response.data.data.user.first_name || '',
                        lastName: response.data.data.user.last_name || '',
                        mobile: response.data.data.user.mobile || '',
                        email: response.data.data.user.email || '',
                        country: response.data.data.country || '',
                        street1: response.data.data.street1 || '',
                        street2: response.data.data.street2 || '',
                        buildingDetails: response.data.data.building || '',
                        city: response.data.data.city || '',
                        state: response.data.data.state || '',
                        zipcode: response.data.data.zip || ''
                    });
                    setInitialMobile(response.data.data.user.mobile || '');
                    setInitialEmail(response.data.data.user.email || '');
                }
            } catch (error) {
                console.error("Unexpected error:", error);
            }
        };

        fetchProfileData();
    }, []);

    //  all the toggles
    const handleEditToggle = () => {
        setIsRequired(true)
        setIsEditable(!isEditable);
    };

    const handleChangePassToggle = () =>{
        setIsChangePass(!ischangePass);
    };

    const closeOtpPopUp = () =>{
        setShowOtpPopUp(false);
    }

    const handleOtpChange = (name, value) => {
        setProfileData(prevData => ({
            ...prevData,
            [name]: value // Update the OTP field dynamically
        }));
    };

    const handleSendOTP = async() =>{
        const body={
            "mobile":profileData.mobile
        }
        try{
            const response = await SendOTP({body})
            if (response.error){
                console.log("error sending otp")
                return false;
            }
            else{
                if (response.data.resp_code === 1){
                    return true;
                }
                else{
                    return false;
                }
            }
        }
        catch(error){
            console.log("error sending otp",error)
            return false;
        }
    }

    const handleVerifyOtp = async() =>{

        const body = {
            "mobile":profileData.mobile,
            "otp":profileData.otp
        }
        if(!profileData.mobile.trim().length>0){
            setOtpError("Please enter the Otp to continue")
        }

        try{
            const response = await VerifyOTP({body})
            if (response.error){
                console.log(response)
                setOtpError("Otp doesn't match. Please try again.")
                return;
            }
            else{
                if (response.data.resp_code === 1){
                    setOtpError("");
                    setShowOtpPopUp(false);
                    submitMyProfileData();
                }
                else{
                    setOtpError("Otp doesn't match. Please try again.")
                    return;
                }
            }
        }
        catch(error){
            console.log("error sending otp",error)
            return;
        }
    }

    const handleSave =  async() => {
        if(!profileData.firstName.length>0){
            window.scrollTo({ top: 0, behavior: "smooth" });
            setErrorMessage("Enter your first name to continue")
            return;
        }
        else if(!profileData.mobile.length>0){
            window.scrollTo({ top: 0, behavior: "smooth" });
            setErrorMessage("Enter your mobile number to continue")
            return;
        }
        else if(!profileData.email.length>0){
            window.scrollTo({ top: 0, behavior: "smooth" });
            setErrorMessage("Enter your email to continue")
            return;
        }
        if(profileData.email !== initialEmail){
            const body = {
                "mobile":profileData.mobile,
                "email":profileData.email
            }
            
            const response = await UserExists({body})
            if(response.data.email_exists){
                window.scrollTo({ top: 0, behavior: "smooth" });
                setErrorMessage("Email already exists.Try another one.")
                return;
            }
        }
        if (profileData.mobile !== initialMobile) {
            const body = {
                "mobile":profileData.mobile,
                "email":profileData.email
            }
            const response = await UserExists({body})
            if(response.data.mobile_exists){
                window.scrollTo({ top: 0, behavior: "smooth" });
                setErrorMessage("Mobile number already exists. Try another one.")
                return;
            }

            const result = handleSendOTP();
            if (result){
                setErrorMessage("");
                setShowOtpPopUp(true);
                return;
            }else{
                return;
            }
        }

        // location field validations
        setLocationErrorMessage("");
        const { country,state,street1,buildingDetails,zipcode,city } = profileData;
        const fields = {"country":country,"state":state,"street 1":street1,"building details":buildingDetails,"city":city,"ZIP code":zipcode}
        const message = LocationValidator({fields})
        const locationContainer = document.getElementById('location-container');
        if (message.length>0){
            locationContainer.scrollIntoView({ behavior: "smooth", block: "start" });
            setLocationErrorMessage(message);
            return
        }
        submitMyProfileData();
    };

    const submitMyProfileData = async() =>{
        setErrorMessage("")
        const body ={
            "user": {
                "first_name":profileData.firstName ,
                "last_name":profileData.lastName ,
                "mobile": profileData.mobile,
                "email":profileData.email 
            },
            "country": profileData.country,
            "state": profileData.state,
            "city": profileData.city,
            "street1": profileData.street1,
            "street2": profileData.street2,
            "building": profileData.buildingDetails,
            "zip": profileData.zipcode
        }
        try {
            const response = await fetchWithAuth(UpdateProfileAPI, {body})

            if (response.error) {
                console.error("Error updating profile:", response.error);
            } else {
                console.log("Profile updated successfully!");
                setIsEditable(false); // Disable editing mode
                setIsRequired(false); 
                setNavKey(prevKey => prevKey + 1);
            }
        } catch (error) {
            console.error("Unexpected error while saving profile:", error);
        }
    }

    // Reset password section
    const handleSendOtp = async () => {
        
        const body={
            "mobile":profileData.mobile
        }
        try {
            const response = await SendOTP({ body });
            if (response.error){
                setErrorMessage("error sending otp");
                return;
            }
            else{
                if (response.data.resp_code === 1){
                    setErrorMessage("");
                }
                else{
                    setErrorMessage("Error sending OTP. Please try again.");
                    return;
                }
            }
            
        } catch (error) {
            setErrorMessage("Something went wrong. Please try again.");
            return;
        }
    };
    const openResetPassForm  = () =>{
        handleSendOtp();
        handleChangePassToggle();
    }
    const handleChangePassFormClose = () =>{
        setIsChangePass(false);
    }
    const handleSuccesChangePass = () =>{
        navigate("/");
        logout();
    }
    const toggleDeleteAccountPopUp = () =>{
        setIsDeleteAccount(!isDeleteAccount);
    }
    const handleDeleteAccount = async() =>{
        const body ={}
        try {
            const response = await fetchWithAuth(DeleteAccountAPI, {body})
            if (response.error) {
                console.error("Error Deleting profile:", response.error);
            } else {
                console.log("Profile Deleted successfully!");
                setIsDeleteSuccess(true);
                setTimeout(() => {
                    setIsDeleteSuccess(false);
                    logout();

                }, 2000);
            }
        } catch (error) {
            console.error("Unexpected error while Deleting profile:", error);
        }

    }
    const closeDeletePopUp = () =>{
        setIsDeleteAccount(false);
    }

    return (
        <div className="body-container">
            <div>
                <NavBarMain key={navKey}/>
                <div className="my-profile-main-container">
                    <div className="my-profile-sub-container">
                        <span className="my-profile-heading">My Profile</span>
                        <div className="profile-cards-containers">
                            <div className="user-name-section">
                                <UserIconAndName 
                                    UserName={`${profileData.firstName} ${profileData.lastName}`}
                                />
                            </div>
                            {errorMessage &&
                            <div className="error-message-wrap">
                                <ErrorMessage message={errorMessage}/>
                            </div>
                            }
                            <div className="input-fields-container">
                                <div className="half-width-input">
                                    <InputTextField
                                        htmlFor={"firstname"}
                                        content={"First Name"}
                                        value={profileData.firstName}
                                        onChange={(e) => setProfileData({ ...profileData, firstName: e.target.value })}
                                        readOnly={!isEditable}
                                        required={isRequired}
                                    />
                                    <InputTextField
                                        htmlFor={"lastname"}
                                        content={"Last Name"}
                                        value={profileData.lastName}
                                        onChange={(e) => setProfileData({ ...profileData, lastName: e.target.value })}
                                        readOnly={!isEditable}
                                    />
                                </div>
                                <InputTextField
                                    htmlFor={"mobile"}
                                    content={"Mobile"}
                                    type={"mobile"}
                                    value={profileData.mobile}
                                    onChange={(e) => setProfileData({ ...profileData, mobile: e.target.value })}
                                    readOnly={!isEditable}
                                    required={isRequired}
                                />
                                <InputTextField
                                    htmlFor={"email"}
                                    content={"Email"}
                                    type={"email"}
                                    value={profileData.email}
                                    onChange={(e) => setProfileData({ ...profileData, email: e.target.value })}
                                    readOnly={!isEditable}
                                    required={isRequired}
                                />
                            </div>
                        </div>
                        <div className="profile-cards-containers" id="location-container">
                            <div className="input-fields-container">
                                <div className="profile-card-title">Location</div>
                                {locationErrorMessage &&
                                    <div className="error-message-wrap">
                                        <ErrorMessage message={locationErrorMessage}/>
                                    </div>
                                }
                                <InputTextField
                                    htmlFor={"country"}
                                    content={"Country"}
                                    value={profileData.country}
                                    onChange={(e) => setProfileData({ ...profileData, country: e.target.value })}
                                    readOnly={!isEditable}
                                    required={isRequired}
                                />
                                <div className="half-width-input">
                                    <InputTextField
                                        htmlFor={"street1"}
                                        content={"Street 1"}
                                        value={profileData.street1}
                                        onChange={(e) => setProfileData({ ...profileData, street1: e.target.value })}
                                        readOnly={!isEditable}
                                        required={isRequired}
                                    />
                                    <InputTextField
                                        htmlFor={"street2"}
                                        content={"Street 2"}
                                        value={profileData.street2}
                                        onChange={(e) => setProfileData({ ...profileData, street2: e.target.value })}
                                        readOnly={!isEditable}
                                    />
                                </div>
                                <InputTextField
                                    htmlFor={"builing-details"}
                                    content={"House/Building Number/Apartment"}
                                    value={profileData.buildingDetails}
                                    onChange={(e) => setProfileData({ ...profileData, buildingDetails: e.target.value })}
                                    readOnly={!isEditable}
                                    required={isRequired}
                                />
                                <div className="half-width-input">
                                    <InputTextField
                                        htmlFor={"city"}
                                        content={"City"}
                                        value={profileData.city}
                                        onChange={(e) => setProfileData({ ...profileData, city: e.target.value })}
                                        readOnly={!isEditable}
                                        required={isRequired}
                                    />
                                    <InputTextField
                                        htmlFor={"state"}
                                        content={"State/Province/Region"}
                                        value={profileData.state}
                                        onChange={(e) => setProfileData({ ...profileData, state: e.target.value })}
                                        readOnly={!isEditable}
                                        required={isRequired}
                                    />
                                </div>
                                <InputTextField
                                    htmlFor={"zipcode"}
                                    content={"Zip code"}
                                    value={profileData.zipcode}
                                    onChange={(e) => setProfileData({ ...profileData, zipcode: e.target.value })}
                                    readOnly={!isEditable}
                                    required={isRequired}
                                />
                            </div>
                        </div>
                        <div className="all-buttons-box">
                            <div className="save-profile-button-containers">
                                <PrimaryMutedButton
                                    label={"Deactivate Account"}
                                    onClick={toggleDeleteAccountPopUp}
                                    isdelete={true}
                                />
                            </div>
                            <div className="save-profile-button-containers">
                                <PrimaryMutedButton
                                    label={"Change Password"}
                                    onClick={openResetPassForm}
                                />
                                <PrimarySolidButton
                                    label={isEditable ? "Save" : "Edit Profile"}
                                    onClick={isEditable ? handleSave : handleEditToggle}
                                />
                            </div>
                        </div>
                        
                    </div>
                    { ischangePass && 
                        <ResetPasswordPopUp 
                            onClose={handleChangePassFormClose}
                            onComplete={handleSuccesChangePass}
                            mobile={profileData.mobile}
                        />
                    }
                    { isDeleteAccount && <DeleteAccount onDelete={handleDeleteAccount} onClose={closeDeletePopUp} onCancel={toggleDeleteAccountPopUp}/>}
                    { isDeleteSuccess && <ScuccessPopUp message={"Your account has been deactivated successfully."}/> }
                    { showOtpPopUp && <ValidateMobilePopUp onSubmit={handleVerifyOtp} onClose={closeOtpPopUp}
                    onInputChange={handleOtpChange} otpError={otpError}/>}
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default MyProfilePage;

