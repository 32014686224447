import React,{useState} from "react";
import './InputPasswordField.css'

const PasswordInput = ({htmlFor,id,content,value,onChange,isArabic=false,dir="ltr",hasPhoneIcon=true,name,required=false}) =>{
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const togglePasswordVisibility = () => {
        setIsPasswordVisible(!isPasswordVisible);
    };
    return(
        <div className={`phone-input-wrapper ${isArabic?"yes-arabic":""}`}>
            <label htmlFor={htmlFor}>
                {isArabic && required && <span style={{ color: "red", marginRight: "4px" }}>*</span>}
                {content}
                {!isArabic && required && <span style={{ color: "red", marginLeft: "4px" }}>*</span>}
            </label>
            <div className={`phone-input-container ${isArabic?"phone-input-container-ar":""}`}>
                <div className={`lock-icon-container ${isArabic?"lock-icon-container-ar":""} ${hasPhoneIcon?"":"hide"}`}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" fill="none">
                    <path d="M10.9999 15.5833C11.5062 15.5833 11.9166 15.1729 11.9166 14.6667C11.9166 14.1604 11.5062 13.75 10.9999 13.75C10.4937 13.75 10.0833 14.1604 10.0833 14.6667C10.0833 15.1729 10.4937 15.5833 10.9999 15.5833Z" stroke="#666666" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M17.4167 9.16699H4.58333C3.57081 9.16699 2.75 9.9878 2.75 11.0003V18.3337C2.75 19.3462 3.57081 20.167 4.58333 20.167H17.4167C18.4292 20.167 19.25 19.3462 19.25 18.3337V11.0003C19.25 9.9878 18.4292 9.16699 17.4167 9.16699Z" stroke="#666666" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M6.41675 9.16634V6.41634C6.41675 5.20077 6.89963 4.03498 7.75918 3.17544C8.61872 2.31589 9.78451 1.83301 11.0001 1.83301C12.2157 1.83301 13.3814 2.31589 14.241 3.17544C15.1005 4.03498 15.5834 5.20077 15.5834 6.41634V9.16634" stroke="#666666" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
                <input
                    type={isPasswordVisible ? "text" : "password"}
                    id={id}
                    value={value}
                    onChange={onChange}
                    dir={dir}
                    name={name}

                />
                <div className={`eye-icon-container ${isArabic?"eye-icon-container-ar":""}`} onClick={togglePasswordVisibility}>
                    {isPasswordVisible ? (
                            // Eye-crossed icon for "hide password"
                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                            <path d="M9.83862 4.6532C11.9739 4.39873 14.1338 4.85011 15.9884 5.93841C17.8431 7.0267 19.2906 8.6921 20.1099 10.6803C20.1863 10.8861 20.1863 11.1125 20.1099 11.3183C19.773 12.135 19.3278 12.9027 18.7862 13.6008" stroke="#787878" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M12.9103 12.9783C12.3917 13.4793 11.697 13.7565 10.976 13.7502C10.2549 13.7439 9.56521 13.4547 9.05533 12.9448C8.54546 12.435 8.25625 11.7452 8.24998 11.0242C8.24372 10.3032 8.5209 9.6085 9.02184 9.08984" stroke="#787878" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M16.0224 16.041C14.8065 16.7613 13.4498 17.2116 12.0444 17.3613C10.6391 17.5111 9.21797 17.3568 7.87751 16.9089C6.53705 16.4611 5.30861 15.7301 4.27554 14.7656C3.24248 13.8012 2.42896 12.6258 1.89018 11.3192C1.81379 11.1134 1.81379 10.887 1.89018 10.6812C2.70293 8.71025 4.13297 7.05602 5.96568 5.9668" stroke="#787878" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M1.83337 1.83301L20.1667 20.1663" stroke="#787878" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        ) : (
                            // Eye icon for "show password"
                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                            <path d="M1.89018 11.3191C1.81379 11.1133 1.81379 10.8869 1.89018 10.6811C2.63424 8.87695 3.89724 7.33437 5.51906 6.24891C7.14087 5.16345 9.04847 4.58398 11 4.58398C12.9516 4.58398 14.8592 5.16345 16.481 6.24891C18.1028 7.33437 19.3658 8.87695 20.1098 10.6811C20.1862 10.8869 20.1862 11.1133 20.1098 11.3191C19.3658 13.1232 18.1028 14.6658 16.481 15.7513C14.8592 16.8367 12.9516 17.4162 11 17.4162C9.04847 17.4162 7.14087 16.8367 5.51906 15.7513C3.89724 14.6658 2.63424 13.1232 1.89018 11.3191Z" stroke="#666666" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M11 13.75C12.5188 13.75 13.75 12.5188 13.75 11C13.75 9.48122 12.5188 8.25 11 8.25C9.48122 8.25 8.25 9.48122 8.25 11C8.25 12.5188 9.48122 13.75 11 13.75Z" stroke="#666666" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        )}
                </div>
            </div>
        </div>
    )
}

export default PasswordInput;