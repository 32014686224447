// import React, { useEffect, useState } from "react";
// import "./SelectField.css";

// const SelectField = ({
//     htmlFor,
//     content,
//     dvalue,
//     name,
//     onChange,
//     options, // Options passed from the parent component
//     readOnly = false,
//     dir = "ltr",
//     isArabic = false,
//     required = false,
// }) => {

//     const [newVal,setNewVal] = useState("");
//     useEffect(() => {

//         console.log("the passed value is", dvalue);
//         console.log("Available options:", options);
//         setNewVal(dvalue || "");
//     }, [dvalue, options]);


//     useEffect(() => {
//         console.log("the new value is", newVal,typeof(newVal));
//     }, [newVal]);

//     const handleChange = (e) => {
//         setNewVal(e.target.value); // Update local state
//         onChange(e); // Pass the selected value to the parent form
//     };

//     return (
//     <div className={`text-input-container country-code ${isArabic ? "yes-arabic" : ""}`}>
//         <label htmlFor={htmlFor}>
//             {isArabic && required && <span style={{ color: "red", marginRight: "4px" }}>*</span>}
//             {content}
//             {!isArabic && required && <span style={{ color: "red", marginLeft: "4px" }}>*</span>}
//         </label>
//         <select 
//             dir={dir} 
//             value={newVal}
//             name={name} 
//             onChange={handleChange} 
//             disabled={readOnly}
//         >
//             <option value="" >
//             Select an option
//             </option>
//             {options.map((option, index) => (
//             <option key={index} value={option.value}>
//                 {option.label}
//             </option>
//         ))}
//         </select>
//     </div>
//     );
// };

// export default SelectField;




import React, { useState, useEffect, useRef } from "react";
import "./SelectField.css";

const SelectField = ({
    htmlFor,
    content,
    dvalue,
    name,
    onChange,
    options,
    readOnly = false,
    dir = "ltr",
    isArabic = false,
    required = false,
}) => {
    const [selectedValue, setSelectedValue] = useState((options?.[1]?.value) || '+966');
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        setSelectedValue(dvalue || options?.[0]?.value || '+966');
    }, [dvalue]);

    const handleSelect = (value) => {
        setSelectedValue(value);
        onChange({ target: { name, value } }); // Mimic event object
        setDropdownOpen(false);
    };

    const handleOutsideClick = (e) => {
        if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
            setDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleOutsideClick);
        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, []);

    return (
        <div className={`text-input-container custom-select-container ${isArabic ? "yes-arabic" : ""}`} ref={dropdownRef}>
            <label htmlFor={htmlFor}>
                {isArabic && required && <span style={{ color: "red", marginRight: "4px" }}>*</span>}
                {content}
                {!isArabic && required && <span style={{ color: "red", marginLeft: "4px" }}>*</span>}
            </label>
            <div
                className={`custom-select-box ${isDropdownOpen ? "open" : ""}`}
                onClick={() => !readOnly && setDropdownOpen(!isDropdownOpen)}
                dir={dir}
            >

                <span>
                    {selectedValue ? options.find(opt => opt.value === selectedValue)?.label : ""}
                </span>
                <span className={`arrow`}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none"><path d="M3 4.5L6 7.5L9 4.5" stroke="#241E20" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                </span>
            </div>
            {isDropdownOpen && !readOnly && (
                <ul className="custom-dropdown">
                    {options.map((option) => (
                        <li key={option.value} onClick={() => handleSelect(option.value)}>
                            {option.label}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default SelectField;