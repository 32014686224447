import React from "react";
import './OtpForm.css';

// Components
import PrimarySolidButton from "../../Buttons/PrimarySolidButton/PrimarySolidButton";
import PopUpMainHeading from "../../Heading/PopUpMainHeading/PopUpMainHeading";
import AccountWarningAR from "../TextItems/AccountWarning/AccountWarningAR";
import PopUpSubHeading from "../../Heading/PopUpSubHeading/PopUpSubHeading";
import InputTextField from "../../InputFields/InputTextField/InputTextField";



const OtpFormAR = ({onSubmit,onInputChange,error,onLoginNow,onClose,onBackButtonClick}) =>{

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        onInputChange(name, value);
    };

    const handleOverlayClick = (e) => {
        if (e.target.className === "login-popup-overlay") {
            onClose();
        }
    };

    return(
        <div className="login-popup-overlay" onClick={handleOverlayClick}>
            <div className="register-pop-up">
                <div className="register-head-section">
                    <PopUpMainHeading content={"الرجاء إدخال كلمة المرور لمرة واحدة"}/>
                    <PopUpSubHeading content={"الرجاء إدخال كلمة المرور لمرة واحدة (OTP) المرسلة إلى هاتفك للتحقق من هويتك."}/>
                </div>
                <div className="back-button-box back-button-box-ar" onClick={onBackButtonClick}>
                    <svg className="rotate-180" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M12 19L5 12L12 5" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M19 12H5" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
                {error && <div className="error-message">{error}</div>}

                <form className="login-input-button-section">
                    <div className="input-field-section">
                        <InputTextField htmlFor={"otp"} name={"otp"} onChange={handleInputChange} content={'أدخل كلمة مرور OTP هنا'} isArabic={true} dir="rtl"/>
                    </div>
                    <div className="forgot-already-account-box margin-new arabic-flex">
                        <AccountWarningAR content={"هل لديك حساب بالفعل؟"}
                        link={"قم بتسجيل الدخول الآن"} onClick={onLoginNow}/>
                        <PrimarySolidButton label={"يكمل"} onClick={onSubmit}/>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default OtpFormAR;