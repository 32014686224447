import React from "react";
import '../Buttons.css';
import './PrimaryMutedButton.css'

const PrimaryMutedButton = ({label,onClick,disabled=false,isdelete=false}) =>{
    return(
        <button className={`btn primary-muted-btn ${disabled ? "disabled" : ""} ${isdelete ? "isdelete" : ""}`}
            onClick={!disabled ? onClick:null}>{label}
        </button>
    )
}

export default PrimaryMutedButton;