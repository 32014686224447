import React,{useState} from "react";
import './CreateAccount.css';
import '../../../Common.css';
import { SendOTP,ResetPassword } from "../../../services/Api";

// components
import PrimarySolidButton from "../../Buttons/PrimarySolidButton/PrimarySolidButton";
import PopUpMainHeading from "../../Heading/PopUpMainHeading/PopUpMainHeading";
import AccountWarning from "../TextItems/AccountWarning/AccountWarning";
import PopUpSubHeading from "../../Heading/PopUpSubHeading/PopUpSubHeading";
import InputTextField from "../../InputFields/InputTextField/InputTextField";
import ScuccessPopUp from "../AccountCreationSuccess/ScuccessPopUp";
import PasswordInput from "../../InputFields/InputPasswordField/InputPasswordField";
import ErrorMessageAR from "../../ErrorMessage/ErrorMessageAR";




const ResetPasswordPopUpAR = ({ onClose,onSubmit,onComplete,mobile }) => {
    const [otpError, setOtpError] = useState(""); 
    const [mobileError,setMobileError] = useState("");
    const [formData, setFormData] = useState({
        mobile: mobile,
        otp: "",
        newPassword: "",
        confirmPassword: "",
    });

    const handleOverlayClick = (e) => {
        if (e.target.className === "login-popup-overlay") {
            onClose();
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };



    const handleResetAccount = async () => {
        const { newPassword, confirmPassword } = formData;
        if (!formData.otp.trim()) {
            setOtpError("الرجاء إدخال كلمة المرور لمرة واحدة.");
        return;
        }
        if(!newPassword.trim().length>0 | !confirmPassword.trim().length>0){
            setOtpError("لا يمكن أن تكون كلمات المرور فارغة.")
            return;
        }
        if (newPassword !== confirmPassword) {
            setOtpError("كلمات المرور غير متطابقة.")
            return;
        }
        const body = {
            "mobile":formData.mobile,
            "otp":formData.otp,
            "password":formData.newPassword,
            "confirm_password":formData.confirmPassword
        }

        try {
            const response = await ResetPassword({ body });
            if (response.error){
                setOtpError("كلمة مرور OTP غير متطابقة. يرجى المحاولة مرة أخرى.")
                return;
            }
            else{
                if (response.data.resp_code === 1){
                    setOtpError("")
                    onComplete();
                }
                else{
                    setOtpError("كلمة مرور OTP غير متطابقة. يرجى المحاولة مرة أخرى.")
                }
            }
            
        } catch (error) {
            console.error("Error verifying OTP:", error);
            setOtpError("حدث خطأ ما. يرجى المحاولة مرة أخرى.");
        }
    };


    return (
    <div className="login-popup-overlay" onClick={handleOverlayClick}>
        <div className="register-pop-up">
            <div className="register-head-section">
            <PopUpMainHeading content={"إعادة تعيين كلمة مرور حسابك"} />
            <PopUpSubHeading
                content={"أدخل بيانات الاعتماد التالية لإعادة ضبط حسابك." }
            />
        </div>
        <form className="login-input-button-section">
        {otpError && <ErrorMessageAR message={otpError}/>}
            {
            <div className="input-field-section">
                <InputTextField
                    htmlFor={"otp"}
                    content={"أدخل كلمة المرور لمرة واحدة"}
                    name={"otp"}
                    value={formData.otp}
                    onChange={handleChange}
                    isArabic={true} dir="rtl"
                />
                <PasswordInput
                    htmlFor={"newPassword"}
                    content={"كلمة المرور الجديدة"}
                    name={"newPassword"}
                    value={formData.newPassword}
                    onChange={handleChange}
                    type="password"
                    isArabic={true} dir="rtl"
                    hasPhoneIcon={false}
                />
                <PasswordInput
                    htmlFor={"confirmPassword"}
                    content={"تأكيد كلمة المرور"}
                    name={"confirmPassword"}
                    value={formData.confirmPassword}
                    onChange={handleChange}
                    type="password"
                    isArabic={true} dir="rtl"
                    hasPhoneIcon={false}
                />
                <div className="button-padding"></div>
                <PrimarySolidButton label={"يُقدِّم"} onClick={handleResetAccount} />
            </div>
            }
        </form>
        </div>
    </div>
    );
};

export default ResetPasswordPopUpAR;
