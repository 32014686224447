import React,{useState} from "react";
import './CreateAccount.css';
import '../../../Common.css';
import { SendOTP,ResetPassword } from "../../../services/Api";

// components
import PrimarySolidButton from "../../Buttons/PrimarySolidButton/PrimarySolidButton";
import PopUpMainHeading from "../../Heading/PopUpMainHeading/PopUpMainHeading";
import AccountWarning from "../TextItems/AccountWarning/AccountWarning";
import PopUpSubHeading from "../../Heading/PopUpSubHeading/PopUpSubHeading";
import InputTextField from "../../InputFields/InputTextField/InputTextField";
import ScuccessPopUp from "../AccountCreationSuccess/ScuccessPopUp";
import PasswordInput from "../../InputFields/InputPasswordField/InputPasswordField";
import ErrorMessage from "../../ErrorMessage/ErrorMessage";




const ValidateMobilePopUp = ({ onClose, onInputChange, onSubmit, otpError }) => {
    const [formData, setFormData] = useState({
        otp: "",
    });

    const handleOverlayClick = (e) => {
        if (e.target.className === "login-popup-overlay") {
            onClose();
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
      onInputChange(name, value); // Pass input data to parent component if needed
    };


    return (
    <div className="login-popup-overlay" onClick={handleOverlayClick}>
        <div className="register-pop-up">
            <div className="register-head-section">
            <PopUpMainHeading content={"Verfiy your new mobile number"} />
            <PopUpSubHeading
                content={"Enter the OTP sent to your new mobile number"}
            />
            </div>
            <form className="login-input-button-section">
                {otpError && <ErrorMessage message={otpError}/>}
                <div className="input-field-section">
                    <InputTextField
                        htmlFor={"otp"}
                        content={"OTP"}
                        name={"otp"}
                        value={formData.otp}
                        onChange={handleChange}
                        type="mobile"
                    />
                    <div className="button-padding"></div>
                    <PrimarySolidButton label={"Submit"} onClick={onSubmit} />
                </div>
            </form>
        </div>
    </div>
    );
};

export default ValidateMobilePopUp;
