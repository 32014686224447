import React from "react";
import './InputTextField.css';



const InputTextField = ({type="text",htmlFor,content,value,name,onChange,readOnly=false,dir="ltr",isArabic=false,required=false}) =>{

    const handleKeyPress = (event) => {
        if (type === "mobile" && !/^[0-9]$/.test(event.key) &&
        !["Backspace", "Delete", "ArrowLeft", "ArrowRight", "Tab"].includes(event.key)
    ) {
            event.preventDefault();
        }
    };
    return(
        <div className={`text-input-container ${isArabic?"yes-arabic":""}`}>
            <label htmlFor={htmlFor}>
                {isArabic && required && <span style={{ color: "red", marginRight: "4px" }}>*</span>}
                {content}
                {!isArabic && required && <span style={{ color: "red", marginLeft: "4px" }}>*</span>}
            </label>
            <input 
                dir={dir}  
                value={value} 
                name={name} 
                onChange={onChange} 
                readOnly={readOnly}
                type={type === "mobile" ? "text" : type}
                inputMode={type === "mobile" ? "numeric" : "text"} 
                pattern={type === "mobile" ? "[0-9]*" : undefined} 
                onKeyDown={handleKeyPress}
            />
        </div>
    )
}

export default InputTextField;