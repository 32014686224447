import React, { useEffect, useState } from "react";
import './ServiceBookedPopUp.css';
import moment from 'moment';


// components
import PrimarySolidButton from "../../Buttons/PrimarySolidButton/PrimarySolidButton";
import PrimaryMutedButton from "../../Buttons/PrimaryMutedButton/PrimaryMutedButton";
import PopUpMainHeading from "../../Heading/PopUpMainHeading/PopUpMainHeading";
import AccountWarning from "../TextItems/AccountWarning/AccountWarning";
import PopUpSubHeading from "../../Heading/PopUpSubHeading/PopUpSubHeading";
import InputTextField from "../../InputFields/InputTextField/InputTextField";
import PrimaryTextAreaField from "../../InputFields/TextAreaField/PrimaryTextField/PrimaryTextAreaField";
// images
import doticon from '../../../assets/dot-icon.gif';

const ServiceBookedPopUp = ({data,onClose,location,onSubmit,subText="booked",readOnly="false",closeButton=true}) =>{

    const [address,setAddress] = useState();
    const handleOverlayClick = (e) =>{
        if(e.target.className === 'login-popup-overlay'){
            onClose();
        };
    }
    useEffect(()=>{
        const address = [
            data.building || '', 
            data.street1 || '', 
            data.street2 || '', 
            data.city || '', 
            data.state || '', 
            data.country || '', 
            data.zip || ''
        ]
        .filter(part => part.trim() !== '') // Remove empty parts
        .join(', '); // Combine with a comma and space

        // Save the address to state
        setAddress(address);
    },[])
    const formattedDate = moment(data.visit_date).format("DD-MMM-YYYY");
    const formattedTime = moment(data.visit_time, "HH:mm:ss").format("hh A");
    return (
        <div className="login-popup-overlay" onClick={handleOverlayClick}>
            <div className="register-pop-up">
                <div className={`closeButton ${closeButton?"":"hide"}`} onClick={onClose}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                    <path d="M21 7L7 21" stroke="#666666" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M7 7L21 21" stroke="#666666" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
                <div className="dot-icon">
                    <img src={doticon} alt="booked" />
                </div>
                <div className="register-head-section">
                    <PopUpMainHeading content={`Great! Your service is ${subText}`} color={"blue"}/>
                    <PopUpSubHeading content={"Our team will reach out to you soon!"}/>
                </div>
                <form className="login-input-button-section">
                    <div className="input-field-section">
                        <InputTextField htmlFor={"service-category"} readOnly={readOnly} value={data.service.category.name} content={'Service Category'}/>
                        <InputTextField htmlFor={"service-name"} readOnly={readOnly} value={data.service.name} content={'Service Name'}/>
                        <PrimaryTextAreaField htmlFor={"location"} readOnly={readOnly} value={address} content={'Location'}/>
                        <InputTextField htmlFor={"scheduled-date"} readOnly={readOnly} value={`${formattedDate} ${formattedTime}`} content={'Sheduled Date and time'}/>
                    </div>
                    <div className="forgot-already-account-box margin-new booked-page-button-style">
                        {/* <PrimaryMutedButton label={"Cancel Request"}/> */}
                        <PrimarySolidButton label={"Continue Booking"} onClick={onSubmit}/>
                    </div>
                </form>
            </div>
        </div>
    )
}
export default ServiceBookedPopUp;